import _ from "lodash"
import React, {useEffect, useRef, useState} from "react"

import {useAppContext} from "@root/src/context"
import I18n from "i18n-js"

import {TinyPagination} from "../TinyPagination"
import {RankingEntriesHolder, RankingEntryView} from "./RankingItem"
import {Panel, Row, Section} from "../Ui"
import {FriendsDropdown} from "../Ui/Dropdown"
import MatchdayDropdown from "../Ui/Dropdown"

const RankingInner: React.FC = () => {
	const {state, dispatch} = useAppContext()

	if (!state.rankingView.config) { return null }

	const {ranking, ranking_types: rankingTypes} = state.rankingView.config
	
	const onChangePage = (page: number) => {
		dispatch({type: "ui__rankingGet", data: {
			page,
			filter: ranking.filter,
			ranking_type: ranking.ranking_type || undefined
		}})
	}

	const onSelectBuddiesOnly = (selectedOption: "all" | "buddies" | "experts") => {
		if(selectedOption === ranking.filter || (selectedOption === "all" && !ranking.filter)) {
			return
		}
		const filter = selectedOption === "all" ? undefined : selectedOption
		dispatch({
			type: "ui__rankingGet",
			data: {
				page: 1,
				filter,
				ranking_type: ranking.ranking_type || undefined,
			},
		})
	}

	const onMatchdaySelect = (item : {id:string,name: string}) => {
		if (item.id === ranking.ranking_type) { return }

		dispatch({type: "ui__rankingGet", data: {
			page: 1,
			filter: ranking.filter,
			ranking_type: item.id
		}})
	}

	return <>
		<Row>
			<div className="neo__ranking__dropdown__container">
				<MatchdayDropdown
					items={rankingTypes}
					activeItem={rankingTypes.find(item => item.id === ranking.ranking_type)}
					onChange={onMatchdaySelect}
				/>
				<FriendsDropdown
					activeItem={ranking.filter ?? "all"}
					onChange={onSelectBuddiesOnly}
				/>
			</div>
		</Row>
		{ranking.entries.length > 0
			? <>
					<Row>
						<RankingEntriesHolder>
							{ranking.entries.map((entry) => {
								return <RankingEntryView
									key={entry.user.id}
									item={entry}
									matchdayId={state.rankingView.config.ranking.ranking_type}
									userIcon={
										entry.user.is_expert
										? "expert"
										: _.some(state.buddies, (b) => b.user.id === entry.user.id)
										? "friend"
										: "none"
									}
									isOwn={state.me.user && state.me.user.id === entry.user.id} />
							})}
						</RankingEntriesHolder>
					</Row>
					{(ranking.total_pages || 0) > 1 &&
						<Row>
							<TinyPagination page={ranking.page} total_pages={ranking.total_pages} toPage={onChangePage} />
						</Row>}
				</>
			: <>
					<Row>
						<div className="neo__buddySearch__noEntriesInfoHolder">
							<span>{I18n.t("ranking.no_entries")}</span>
						</div>
					</Row>
				</>}
		</>
}

const Ranking: React.FC = () => {
	const {state, dispatch} = useAppContext()

	useEffect(() => {
		dispatch({type: "ui__rankingGet", data: {}})

		return () => { // cleanup
		}
	}, [])

	return (
    <Section style="reduced">
      <Panel style="transparent" size="wider" >
				{state.rankingView.config
					? <RankingInner />
					: <div className="neo__ranking__loadingPlaceholder">
							<RankingEntriesHolder>
								{_.times(5, (i) => {
									return <RankingEntryView />
								})}
							</RankingEntriesHolder>
						</div>}
      </Panel>
    </Section>
  )
}

export default Ranking
