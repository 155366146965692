import { useAppContext } from "@root/src/context"
import React, { useEffect } from "react"

import storyBg1 from "@images/story__bg--1.jpg"
import storyBg2 from "@images/story__bg--2.jpg"
import storyBg3 from "@images/story__bg--3.jpg"
import WithTheme from "@root/src/helper/WithTheme"
import ScrollContainer from "react-indiana-drag-scroll"
import { PageTitle, Panel, Row, SectionTitle } from "../Ui"
import I18n from "i18n-js"
import _ from "lodash"
import Button from "../Ui/Button"

const BGS = [storyBg1, storyBg2, storyBg3]

import { Story, StoryPick, UserId } from "@root/types"
import { PlayerImg } from "@root/src/helper/playerImage"
import { BetPoints, PlayerName } from "../Bet"
import { BuddyAvatar } from "../Buddy"
import { useDisableBodyScrollWhenMounted } from "@root/src/helper/useDisableBodyScroll"
import { useHijackCloseIcon } from "../FixedCloseIcon"

export const StorySinglePick: React.FC<{storyPick: StoryPick}> = ({storyPick}) => {
  const className = [
    "neo__story__singlePick",
    storyPick.scored ? `neo__story__singlePick--scored neo__story__singlePick--scored--${storyPick.correct ? "correct" : "wrong"}` : "neo__story__singlePick__notScored",
  ].join(" ")

  return <div className={className}>
    <div className="neo__story__singlePick__imageSection">
      {storyPick.player
        ? <>
            <div className="neo__story__singlePick__playerImageHolder">
              <PlayerImg player={storyPick.player} />
            </div>
          </>
        : <>
            <div className="neo__story__singlePick__placeholderImage" />
          </>}
    </div>
    <div className="neo__story__singlePick__textSection">
      {storyPick.bet_config &&
        <div>
          <div>{I18n.t(`bet_type.${storyPick.bet_config.type}`, {arg: storyPick.bet_config.arg || 0})}</div>
          {storyPick.player
            && <PlayerName player={storyPick.player} />}
        </div>}
    </div>
    <div className="neo__story__singlePick__pointsSection">
      {storyPick.bet_config
        ? <BetPoints
          points={storyPick.scored
            ? storyPick.points || 0
            : storyPick.player?.bet_points[storyPick.bet_config.type] || 0}
          variant={storyPick.scored
            ? (storyPick.correct ? "correct" : "wrong")
            : "default"} />
        : <BetPoints variant="default" />}
    </div>
  </div>
}

const MatchdayNavi: React.FC<{userId: UserId, matchdays: Story["matchdays"][number][], currentMatchdayId: string, swipeThroughUsersScope?: UserId[]}> = ({currentMatchdayId, matchdays, userId, swipeThroughUsersScope}) => {
  // next-id < current!
  const {dispatch} = useAppContext()
  const currentIndex = _.findIndex(matchdays, (md) => currentMatchdayId === md.id)
  if (currentIndex === -1) { return null }

  const nextMd = currentIndex > 0 ? matchdays[currentIndex - 1] : undefined
  const prevMd = currentIndex < matchdays.length - 1 ? matchdays[currentIndex + 1] : undefined

  return <div className="neo__story__mdNavi__holder">
    <div className="neo__story__mdNavi">
      <span className={`neo__story__mdNavi__prev ${prevMd ? "neo__story__mdNavi__prev--active" : ""}`} onClick={prevMd ? () => dispatch({type: "story__showOverlay", data: {userId: userId, matchday: prevMd.id, swipeThroughUsersScope}}) : undefined} />
      <strong>{matchdays[currentIndex].name}</strong>
      <span className={`neo__story__mdNavi__next ${nextMd ? "neo__story__mdNavi__next--active" : ""}`} onClick={nextMd ? () => dispatch({type: "story__showOverlay", data: {userId: userId, matchday: nextMd.id, swipeThroughUsersScope}}) : undefined} />
    </div>
  </div>
}

const UserNavi: React.FC<{users: UserId[], current: UserId, matchday?: string, swipeThroughUsersScope?: UserId[]}> = ({current, users, matchday, swipeThroughUsersScope}) => {
  const { dispatch } = useAppContext()

  return <div className="neo__story__userNavi">
    {_.map(users, (u) => {
      return <a
        key={u}
        onClick={() => dispatch({type: "story__showOverlay", data: {userId: u, matchday, swipeThroughUsersScope}})}
        className={`neo__story__userNavi__entry${current === u ? " neo__story__userNavi__entry--current" : ""}`} />
    })}
  </div>
}

const StoryOverlay: React.FC<{doClose(): void}> = ({doClose}) => {
  const { state, dispatch } = useAppContext()
  useDisableBodyScrollWhenMounted()
  useHijackCloseIcon({callback: doClose})

  if (!state.storyView.userId) { return null }

  const storyConf = state.storyView.config?.story

  useEffect(() => { // mark story of this user as seen
    if (storyConf?.user && !storyConf.countdown_still_open) {
      dispatch({type: "story__markStoryOfUserSeen", data: {userId: storyConf.user.id, matchday: storyConf.matchday_id}})
    }
  }, [storyConf?.user.id, storyConf?.matchday_id, storyConf?.countdown_still_open])

  const isBuddy = !!_.find(state.buddies || [], (b) => {
    return storyConf && storyConf.user.id === b.user.id
  })

  const showRemoveBuddy = isBuddy && !storyConf.user.is_expert
  const showAddBuddy = !isBuddy && state.me.user && storyConf && state.me.user.id !== storyConf.user.id

  return <div className="neo__story__overlay" key={state.storyView.userId} style={{backgroundImage: `url(${BGS[state.storyView.userId % BGS.length]})`}}>
    {false && <div className="neo__story__closeIcon__holder">
      <WithTheme theme="dark">
        <a className="neo__story__closeIcon" onClick={() => doClose()}/>
      </WithTheme>
    </div>}
    {storyConf && <div className="neo__story__userInfoHolder">
      <WithTheme theme="dark">
        <div className="neo__story__userInfo">
          <span>
            <BuddyAvatar user={storyConf.user} />
          </span>
          <span>
            <span className="neo__usernameMax2lines">{storyConf.user.label}</span>
            <span>{I18n.t("matchday.title", {title: storyConf.matchday_name})}</span>
          </span>
        </div>
      </WithTheme>
    </div>}
    {false && storyConf && state.storyView.swipeThroughUsersScope && state.storyView.swipeThroughUsersScope.length > 1 &&
      <div className="neo__story__userNavi__holder">
        <UserNavi current={storyConf.user.id} users={state.storyView.swipeThroughUsersScope} matchday={storyConf.matchday_id} swipeThroughUsersScope={state.storyView.swipeThroughUsersScope} />
      </div>}
    <div className="neo__story__contentWrapper">

      <ScrollContainer mouseScroll={{overscroll: false, rubberBand: false}} hideScrollbars={false}>
        <WithTheme theme="dark">
          <div className="neo__story__content">
            {storyConf
              ? <>
                  <Row>
                    <Panel style="transparent" size="wider">
                      <PageTitle>
                        {state.me.user?.id === state.storyView.userId
                          ? I18n.t("story.title--self", {matchday: storyConf.matchday_name, name: storyConf.user.label || ""})
                          : <span className="neo__usernameMax2lines">
                              {I18n.t("story.title--other", {matchday: storyConf.matchday_name, name: storyConf.user.label || ""})}
                            </span>}
                      </PageTitle>
                    </Panel>
                  </Row>
                  <Row>
                    <Panel style="transparent" size="wider">
                      <div className="neo__story__mdNavi__position">
                        <MatchdayNavi currentMatchdayId={storyConf.matchday_id} matchdays={storyConf.matchdays} userId={storyConf.user.id} swipeThroughUsersScope={state.storyView.swipeThroughUsersScope} />
                      </div>
                    </Panel>
                  </Row>
                  <Row>
                    {!storyConf.user.is_expert && storyConf.countdown_still_open
                      ? <Panel style="transparent" size="slim">
                          <div className="neo__story__censoredInfo">
                            {I18n.t("story.info_censored")}
                          </div>
                        </Panel>
                      : <Panel style="transparent" size="wider">
                          <div className="neo__story__picks">
                            {storyConf
                              ? <>
                                  {_.map(storyConf.picks, (pick, index) => <StorySinglePick key={index} storyPick={pick} />)}
                                </>
                              : <>
                                  {_.times(5, (i) => <StorySinglePick key={i} storyPick={{}} />)}
                                </>}
                          </div>
                        </Panel>}
                  </Row>
                  {state.me.user?.id !== state.storyView.userId && !showRemoveBuddy &&
                    <Row>
                      <div className="neo__story__callToActionHolder">
                        {I18n.t("story.call_to_action")}
                      </div>
                    </Row>}
                  {showRemoveBuddy &&
                    <Row>
                      <Panel size="slim" style="transparent">
                        <div className="neo__story__actionHolder">
                          <Button type="secondary" onClick={() => dispatch({type: "buddies__remove", data: {userId: storyConf.user.id}})}>
                            {I18n.t("story.action_remove_buddy")}
                          </Button>
                        </div>
                      </Panel>
                    </Row>}
                  {showAddBuddy &&
                    <Row>
                      <Panel size="slim" style="transparent">
                        <div className="neo__story__actionHolder">
                          <Button type="primary" onClick={() => dispatch({type: "buddies__add", data: {userId: storyConf.user.id}})}>
                            {I18n.t("story.action_add_buddy")}
                          </Button>
                        </div>
                      </Panel>
                    </Row>}
                </>
              : <></>}
          </div>
        </WithTheme>
      </ScrollContainer>
    </div>
  </div>
}

export default StoryOverlay
