import _ from "lodash"
import React, { PropsWithChildren, useContext, useEffect, useState } from "react"

export type Theme = "dark" | "light"

const ThemeContext = React.createContext<{theme: Theme}>({theme: "light"})

export const useAddThemeToBody = () => {
  const {theme} = useTheme()

  useEffect(() => {
    document.body.classList.add("neo__theme")
    document.body.classList.add("neo__theme__withBackground")
  }, [])

  useEffect(() => {    
    if (theme === "dark") {
      document.body.classList.remove("neo__theme--light")
      document.body.classList.add("neo__theme--dark")
    } else {
      document.body.classList.remove("neo__theme--dark")
      document.body.classList.add("neo__theme--light")
    }
  }, [theme])
}

const WithTheme: React.FC<PropsWithChildren<{theme: Theme, withBackground?: Boolean, fullWindowHeight?: Boolean}>> = ({children, theme, withBackground, fullWindowHeight}) => {
  const [minHeight, setMinHeight] = useState<number>()

  useEffect(() => {
    const onResize = () => {
      if (fullWindowHeight) {
        setMinHeight(window.innerHeight)
      }
    }
    window.addEventListener("resize", onResize)
    onResize()

    return () => { // cleanup
      window.removeEventListener("resize", onResize)
    }
  }, [fullWindowHeight])

  return <ThemeContext.Provider value={{theme}}>
    <div
      className={`neo__theme neo__theme--${theme}${withBackground ? " neo__theme__withBackground" : ""}`}
      style={fullWindowHeight && _.isNumber(minHeight) ? {minHeight: `${minHeight}px`} : undefined}>
        {children}
    </div>
  </ThemeContext.Provider>
}

export const useTheme = () => useContext(ThemeContext)

export default WithTheme
