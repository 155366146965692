import { extractErrorMessage, isServerError } from "@root/types"
import { AppActions } from "../action"
import { AppState } from "../state"
import _ from "lodash"

export default (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case "api__receive/config":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: extractErrorMessage(action.data.error), requiresReload: true}
        }
      } else {
        return {
          ...state,
          config: {
            ...state.config,
            ...action.data,
            requesting: false,
            initialized: true
          }
        }
      }
    case "api__receive/me":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: extractErrorMessage(action.data.error), requiresReload: true}
        }
      } else {
        return {
          ...state,
          me: {
            ...state.me,
            initialized: true,
            user: action.data.user,
            guest: action.data.guest,
            needsToCompleteProfile: action.data.needs_to_complete_profile,
            stats: action.data.stats
          }
        }
      }
    case "api__receive/matchday_get_countdown":
      if (isServerError(action.data)) {
        // ignore error
        return state
      } else {
        return {
          ...state,
          landingView: {
            ...state.landingView,
            countdown: _.isNumber(action.data.countdown_sec) ? { sec: action.data.countdown_sec } : undefined,
            next_matchday_opens_in_sec: _.isNumber(action.data.next_matchday_opens_in_sec) ? { sec: action.data.next_matchday_opens_in_sec } : undefined
          }
        }
      }
    case "api__receive/matchday_get":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: extractErrorMessage(action.data.error), requiresReload: true}
        }
      } else {
        return {
          ...state,
          matchdayView: {
            ...state.matchdayView,
            config: action.data
          }
        }
      }
    case "api__receive/bet_pick":
      const data = action.data
      if (isServerError(data)) {
        return {
          ...state,
          error: {error: extractErrorMessage(data.error), requiresReload: true}
        }
      } else {
        return {
          ...state,
          matchdayView: {
            ...state.matchdayView,
            config: {
              ...state.matchdayView.config,
              matchday: {
                ...state.matchdayView.config.matchday,
                bets: _.map(state.matchdayView.config.matchday.bets, (b) => {
                  return b.id === data.bet.id ? data.bet : b // update bet
                })
              }
            },
            saveResult: data.message // update save result
          }
        }
      }
      //vadym
    case "api__receive/ranking_get":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: action.data.error.description, requiresReload: true}
        }
      } else {
        return {
          ...state,
          rankingView:{
            config: {
              ...state.rankingView.config,
              ranking: action.data.ranking,
              ranking_types: action.data.ranking_types
            }
          }
        }
      }
  }
  return state
}
