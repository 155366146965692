import { extractErrorMessage, isServerError } from "@root/types"
import { AppActions } from "../../action"
import { AppState } from "../../state"
import _ from "lodash"

export default (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case "profile__update__clearState":
      return {
        ...state,
        updateProfileView: {}
      }
    case "api__receive/me_update_profile":
      if (isServerError(action.data)) {
        return {
          ...state,
          updateProfileView: {
            error: extractErrorMessage(action.data.error)
          }
        }
      } else {
        return {
          ...state,
          updateProfileView: {
            ok: action.data.ok
          }
        }
      }
  }
  return state
}
