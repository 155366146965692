import I18n from "i18n-js"
import React, { useEffect, useState } from "react"
import { useAppContext } from "../context"
import routes from "../routes"
import { Panel, Row, Section, SectionTitle } from "../components/Ui"
import { AvatarImage } from "@root/types"
import Button from "../components/Ui/Button"
import { AVATAR_ICONS, AvatarImageView, DEFAULT_AVATAR_ICON } from "../components/Buddy"
import _ from "lodash"

export const AvatarDropdown: React.FC<{selected?: AvatarImage, onSelect(img: AvatarImage): void, placeholderLabel?: string}> = ({onSelect, selected, placeholderLabel}) => {
  const [opened, setOpened] = useState(false)

  return <>
    <div className="neo__complPro__dropdown" onClick={() => setOpened(prev => !prev)}>
      <span>{placeholderLabel || I18n.t("complete_profile.label_avatar")}</span>
    </div>
    {opened && <div className="neo__complPro__chooseAvatar">
        {_.map(AVATAR_ICONS, (icon) => {
          const className = [
            "neo__complPro__chooseAvatar__item",
            selected.type === "icon" && selected.arg === icon ? "neo__complPro__chooseAvatar__item--selected" : "neo__complPro__chooseAvatar__item--notSelected",
          ].join(" ")

          return <div className={className} key={`icon--${icon}`} onClick={() => {onSelect({type: "icon", arg: icon}); setOpened(false)}}>
            <AvatarImageView image={{type: "icon", arg: icon}} />
          </div>
        })}
      </div>}
  </>
}

export const Input: React.FC<{maxLength?: number, placeholder?: string, label?: string, value: string, onChange(val: string): void, cancel?(): void, disableAllAutoStuff?: boolean}> = ({label, onChange, value, placeholder, cancel, disableAllAutoStuff, maxLength}) => {
  return <div className="neo__complPro__input__holder">
    {label && <div className={`neo__complPro__input__label${value === "" || !value ? " neo__complPro__input__label--hide" : ""}`}>{label}</div>}
    {cancel && <div className="neo__complPro__input__cancelIcon" onClick={() => cancel()}/>}
    <input
      type="text"
      placeholder={placeholder}
      className="neo__complPro__input"
      maxLength={_.isNumber(maxLength) ? maxLength : undefined}
      onChange={(evt) => onChange(evt.target.value)}
      value={value}
      {...disableAllAutoStuff ? {
        autoComplete: "off",
        autoCorrect: "off",
        autoCapitalize: "off",
        spellCheck: false
      } : {}}
      />
  </div>
}

export interface CompleteProfileFormVals {
  nickname: string
  avatar: AvatarImage
}

export const NICKNAME_MAX_LENGTH = 30

const CompleteProfileView: React.FC = () => {
  const {state, dispatch, navigate} = useAppContext()
  const [formVals, setFormVals] = useState<CompleteProfileFormVals>({
    nickname: "",
    avatar: { type: "icon", arg: DEFAULT_AVATAR_ICON} // default
  })

  useEffect(() => { // navigate to 'matchday' after completion
    if (!state.me.needsToCompleteProfile) {
      navigate(routes.matchday())
    }
  }, [state.me.needsToCompleteProfile])

  useEffect(() => { // clear error on enter
    dispatch({type: "ui__setCompleteProfileError", data: {error: undefined}})
  }, [])

  return <>
    <Section style="reduced">
      <Panel size="slim" style="transparent">
        <Row>
          <SectionTitle>{I18n.t("complete_profile.title")}</SectionTitle>
        </Row>
        <Row>
          <div className="neo__complPro__selectedAvatar">
            <AvatarImageView image={formVals.avatar} />
          </div>
        </Row>
        <Row>
          <AvatarDropdown
            onSelect={(avatar) => setFormVals((prev) => { return {...prev, avatar}})}
            selected={formVals.avatar} />
        </Row>
        <Row>
          <Input
            label={I18n.t("complete_profile.label_nickname")}
            maxLength={NICKNAME_MAX_LENGTH}
            value={formVals.nickname}
            placeholder={I18n.t("complete_profile.label_nickname")}
            onChange={(nickname) => setFormVals((prev) => { return {...prev, nickname}})} />
        </Row>
        {state.completeProfileView.error && <Row>
            <div className="neo__complPro__errorHolder">{state.completeProfileView.error}</div>
          </Row>}
        <Row>
          <div className="neo__complPro__actionHolder">
            <Button
              onClick={() => dispatch({type: "ui__sendCompleteProfileForm", data: formVals})}
              type="primary"
              disabled={formVals.nickname === ""}>{I18n.t("complete_profile.action_confirm")}</Button>
          </div>
        </Row>
      </Panel>
    </Section>
  </>
}

export default CompleteProfileView
