import React, { useEffect, useState } from "react"
import { useAppContext } from "../context"
import routes from "../routes"
import { Panel, Row, Section, SectionTitle } from "../components/Ui"
import { AvatarImageView, DEFAULT_AVATAR_ICON } from "../components/Buddy"
import I18n from "i18n-js"
import { AvatarDropdown, CompleteProfileFormVals, Input, NICKNAME_MAX_LENGTH } from "./CompleteProfileView"
import Button from "../components/Ui/Button"

const UpdateProfileView: React.FC = () => {
  const {state, dispatch, navigate} = useAppContext()
  const [formVals, setFormVals] = useState<CompleteProfileFormVals>({
    nickname: state.me.user?.label || "",
    avatar: state.me.user?.image || { type: "icon", arg: DEFAULT_AVATAR_ICON}
  })


  useEffect(() => {
    dispatch({type: "profile__update__clearState"})

    return () => { // cleanup
      dispatch({type: "profile__update__clearState"})
    }
  }, [])

  useEffect(() => {
    if (state.updateProfileView.ok === true) {
      navigate(routes.matchday())
    }
  }, [state.updateProfileView.ok])

  return <>
    <Section style="reduced">
      <Panel size="slim" style="transparent">
        <Row>
          <SectionTitle>{I18n.t("update_profile.title")}</SectionTitle>
        </Row>
        <Row>
          <div className="neo__complPro__selectedAvatar">
            <AvatarImageView image={formVals.avatar} />
          </div>
        </Row>
        <Row>
          <AvatarDropdown
            onSelect={(avatar) => setFormVals((prev) => { return {...prev, avatar}})}
            selected={formVals.avatar}
            placeholderLabel={I18n.t("update_profile.label_avatar")} />
        </Row>
        <Row>
          <Input
            cancel={(state.me.user && (state.me.user.label !== formVals.nickname)) ? () => setFormVals(prev => { return {...prev, nickname: state.me.user.label}}): undefined}
            label={I18n.t("update_profile.label_nickname")}
            maxLength={NICKNAME_MAX_LENGTH}
            value={formVals.nickname}
            placeholder={I18n.t("update_profile.label_nickname")}
            onChange={(nickname) => setFormVals((prev) => { return {...prev, nickname}})} />
        </Row>
        {state.updateProfileView.error && <Row>
            <div className="neo__complPro__errorHolder">{state.updateProfileView.error}</div>
          </Row>}
        <Row>
          <div className="neo__complPro__actionHolder">
            <Button
              onClick={() => dispatch({type: "profile__update__sendForm", data: formVals})}
              type="primary"
              disabled={formVals.nickname === ""}>{I18n.t("update_profile.action_confirm")}</Button>
            <Button
              onClick={() => navigate(routes.matchday())}
              type="secondary"
              disabled={formVals.nickname === ""}>{I18n.t("update_profile.action_abort")}</Button>
          </div>
        </Row>
      </Panel>
    </Section>
  </>
}

export default UpdateProfileView
