import { spawn, take } from "redux-saga/effects"
import { all, put, takeEvery, getContext, select } from "redux-saga/effects"
import serverRequestSaga from "./serverRequestSaga"
import uiSaga from "./uiSaga"
import _, { forIn } from "lodash"

const sagaContext = require.context("../", true, /\.saga\.(ts|tsx)$/)

export default function* mainSaga() {
  const keys = _.filter(sagaContext.keys(), k => k.indexOf("./") ===0)
  for (let i=0; i<keys.length; i++) {
    yield spawn(sagaContext(keys[i]).default)
  }
  yield spawn(serverRequestSaga)
  yield spawn(uiSaga)
}
