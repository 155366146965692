import _ from "lodash"

export type ConfigVersion = string

export type UserId = number

export interface AvatarImage {
  type: "icon" | "avatar" | "url"
  arg: string
}

export interface User {
  id: UserId
  label?: string
  image?: AvatarImage
  is_expert?: boolean
}

export const BET_TYPES = [
 "goal_or_assist",
 "duels_won",
 "winning_team",
 "shots_held",
 "clean_sheet",
] as const

export interface MeStat {
  val: number,
  trend?: "up" | "down" | "same"
}

export type BetType = typeof BET_TYPES[number]

export interface BootConfig {
  player_image_path: string
  players: Player[],
  teams: Team[]
  config_version: string
}

export interface ServerErrorResp {
  error: {
    type: string
    description: string
  }
}

export const extractErrorMessage = (error: any): string => {
  if (_.isString(error)) { return error }
  if (error.description) { return error.description }
  return ""
}

export const isServerError = (resp: any): resp is ServerErrorResp => {
  return "error" in resp
}

export interface DisplayError {
  error: string
  requiresReload?: boolean
}

export type AttendanceForecast =
  | "attending"
  | "unknown"
  | "missing"

export interface BetConfig {
  type: BetType
  arg?: number
}

export type TeamUid = string
export type TeamCode = string

export interface Team {
  uid: TeamUid
  team_code: TeamCode
  logo: string
  name: string
}

export type PlayerId = string

export interface MeStats {
  weekly_rank?: MeStat
  season_rank?: MeStat
  season_points?: MeStat
}

export interface Player {
  id: PlayerId
  external_uid: string
  nickname?: string
  first_name?: string
  last_name?: string
  team_code: string
  attendance_forecast?: AttendanceForecast
  disabled?: boolean
  stats?: {
    goals: number
    assists: number
  },
  bet_points: {[type in BetType]: number}
}

export type BetId = number

export interface Bet {
  id: BetId
  config: BetConfig
  pick?: Player

  result?: {
    correct: boolean
    points: number
  }
}

export type BetResult = Bet["result"]

export type MatchdayState =
  | "open"
  | "in_progress"
  | "scored"
  | "cancelled"

export interface Matchday {
  id: number
  title: string
  subtitle: string

  countdown_sec?: number
  state: MatchdayState

  cancelled_info?: string

  bets: Bet[]
}

export interface Buddy {
  user: User
  has_new_story?: boolean
}

export interface RankingEntry {
  user: User
  rank: number
  score: number
}
export type FilterMode = "buddies" | "experts"

export interface Story {
  matchday_name: string
  matchday_id: string
  matchdays: {id: string, name: string}[]
  countdown_still_open?: boolean
  user: User
  picks: {
    player?: Player
    bet_config?: BetConfig
    scored?: boolean
    correct?: boolean
    points?: number
  }[]
}

export type StoryPick = Story["picks"][number]
export type Tab = "picks" | "leaderboard"
