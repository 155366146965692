import { isServerError } from "@root/types"
import { AppActions } from "../action"
import { AppState } from "../state"
import { MaintenanceActions } from "../action/maintenanceActions"

export default (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case "maintenance__markConfigRequesting":
      return {
        ...state,
        config: {
          ...state.config,
          requesting: true
        }
      }
    case "maintenance__clearLandingCountdown":
      return {
        ...state,
        landingView: {
          ...state.landingView,
          countdown: undefined,
          next_matchday_opens_in_sec: undefined
        }
      }
    case "maintenance__clearMatchday":
      return {
        ...state,
        matchdayView: {
          ...state.matchdayView,
          config: undefined
        }
      }
      case "maintenance__clearRankings":
      return {
        ...state,
        rankingView: {
          config: undefined
        }
      }
  }
  return state
}
