import { ServerErrorResp, extractErrorMessage, isServerError } from "@root/types"
import { ActionForType, AppActionType, AppActions } from "../../action"
import { spawn, take } from "redux-saga/effects"
import { all, put, takeEvery, getContext, select } from "redux-saga/effects"
import _ from "lodash"
import { AppState } from "../../state"
import { ApiReq, ApiRequestBetPick, ApiRequestConfig, ApiRequestMarkScoringAsSeen, ApiRequestMatchdayGet, ApiRequestMatchdayGetCountdown, ApiRequestMe, ApiRequestMeCompleteProfile, ConfigVersionScope, actionTypes, apiPath } from "../../action/serverApiActions"
import { ApiRequestStoryView } from "./actions"

const showOverlay = function*(action: ActionForType<"story__showOverlay">) {
  yield put<AppActions>({type: "busy__setBusy"})
  yield put<AppActions>({type: "story__clearConfig"})
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/story_view"),
    data: {
      user_id: action.data.userId,
      matchday: action.data.matchday || undefined
    } as ApiReq<ApiRequestStoryView>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/story_view")))
  yield put<AppActions>({type: "busy__setNotBusy"})
}

export default function* () {
  yield all([
    takeEvery<AppActionType>("story__showOverlay", showOverlay),
  ])
}
