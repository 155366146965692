const LOGO_MAP = {
  "HDH": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Heidenheim.png",
  "FCU": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Union-Berlin.png",
  "M05": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Mainz.png",
  "B04": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Leverkusen.png",
  "BVB": "https://assets.bundesliga.com/contender/2023/5/BVB_Standard_Logo_RGB__3_.png",
  "BMG": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Moenchengladbach.png",
  "SGE": "https://assets.bundesliga.com/tachyon/sites/2/2022/06/Frankfurt-SGE.png",
  "FCA": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Augsburg.png",
  "FCB": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Bayern.png",
  "STP": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/St-Pauli.png",
  "KSV": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Kiel.png",
  "RBL": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Leipzig.png",
  "SCF": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Freiburg.png",
  "SVW": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Bremen.png",
  "TSG": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Hoffenheim.png",
  "VFB": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Stuttgart.png",
  "BOC": "https://assets.bundesliga.com/tachyon/sites/2/2021/08/Bochum.png",
  "WOB": "https://assets.bundesliga.com/contender/2023/5/WOB_Standard_Logo_RGB__2_.png",
}

export const teamLogoUrl = (teamLogoId: string) => {
  return (LOGO_MAP as any)[teamLogoId.toUpperCase()] || ""
}
