import _ from "lodash"
import { Reducer } from "react"
import serverResponseReducer from "./serverResponseReducer"
import { AppState } from "../state"
import { AppActions } from "../action"
import maintenanceReducer from "./maintenanceReducer"
import uiReducer from "./uiReducer"

const busyReducers = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case "busy__setBusy":
      return {
        ...state,
        busy: true
      }
    case "busy__setNotBusy":
      return {
        ...state,
        busy: false
      }
  }
  return state
}

const errorReducers = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case "displayError__set":
      return {
        ...state,
        error: action.data
      }
    case "displayError__clear":
      const res = _.clone(state)
      delete res.error
      return res
  }
  return state
}

const reducerContext = require.context("../", true, /\.reducer\.(ts|tsx)$/)

const rootReducer: Reducer<AppState, AppActions> = (state, action) => {
  const stateClone = {...state}
  return _.reduce([
    serverResponseReducer,
    maintenanceReducer,
    busyReducers,
    errorReducers,
    uiReducer,
    ...(_.chain(reducerContext.keys()
      .filter(k => k.indexOf("./") === 0)
      .map(k => reducerContext(k).default))
      .value())
  ], (prevState, reducer) => reducer(prevState, action), stateClone)
}

export default rootReducer
