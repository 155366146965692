import React, { PropsWithChildren } from "react";

export const Row: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ui__row">{children}</div>
export const SeparatorLine: React.FC = () => <div className="neo__separator__line"></div>
export const SeparatorLineVertical: React.FC = () => <div className="neo__separator__line--vertical"></div>

export const PageWrapper: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ui__pageWrapper">{children}</div>
export const SectionTitle: React.FC<PropsWithChildren> = ({children}) => <h3 className="neo__ui__sectionTitle"><span><span>{children}</span></span></h3>
export const PageTitle: React.FC<PropsWithChildren> = ({children}) => <h2 className="neo__ui__pageTitle"><span><span>{children}</span></span></h2>
export const Panel: React.FC<PropsWithChildren<{style: "transparent" | "card", size: "slim" | "full" | "wider", fullContent?: boolean}>> = ({children, style, size, fullContent}) => {
  const className = [
    "neo__ui__panel",
    `neo__ui__panel--${fullContent ? "fullContent" : "paddedContent"}`,
    `neo__ui__panel--${style}`,
    `neo__ui__panel--${size}`,
  ].join(" ")
  return <div className={className}>{children}</div>
}

export const Section: React.FC<PropsWithChildren<{style: "hard" | "reduced" | "transparent", fullWidth?: boolean}>> = ({children, style, fullWidth}) => {
  const className = [
    "neo__ui__section",
    `neo__ui__section--${style}`,
    fullWidth ? "neo__ui__section--fullWidth" : ""
  ].join(" ")
  
  return <div className={className}>{children}</div>
}
