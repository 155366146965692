import axios, { AxiosResponse } from "axios"
import React, { PropsWithChildren, createContext, useContext } from "react"

export const createSagaJSONPostApiContext = (authToken: string, locale: string) => {
  return {
    post<Resp> (path: string, data?: any) { return apiPost<Resp>(path, authToken, locale, data) },
  }
}

export const JSON_POST_API_CONTEXT_KEY = "JSONPostApi"

export type JSONPostApiResp<T> = AxiosResponse<T>

export type JSONPostApiSagaContext = ReturnType<typeof createSagaJSONPostApiContext>

function apiPost<Resp = any> (path: string, authToken: string, locale: string, data?: any) {
  return axios.post<Resp>(
    path,
    {...data},
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Locale': locale,
        'Authorization': `Bearer ${authToken || ""}`
      }
    }
  )
}
const JSONPostApiContext = createContext<{apiPost<Resp = any>(path: string, data?: any): Promise<AxiosResponse<Resp[], any>>}>({apiPost: () => new Promise(() => {})})

export const useApiPost = () => { return useContext(JSONPostApiContext) }

export const WithApiPost: React.FC<PropsWithChildren<{authToken: string, locale: string}>> = ({authToken, locale, children}) => {
  return <JSONPostApiContext.Provider value={{
    apiPost<Req = any>(path: string, data?: any) {
      return apiPost<Req>(path, authToken, locale, data)
    }
  }}>
    {children}
  </JSONPostApiContext.Provider>
}

export const WithMockApiPost: React.FC<PropsWithChildren> = ({children}) => {
  return <JSONPostApiContext.Provider value={{
    apiPost<Req = any>(path: string, data?: any) {
      console.log("Api request", path, data)
      return new Promise(() => {})
    }
  }}>
    {children}
  </JSONPostApiContext.Provider>
}
