import { PlayerImg } from "@root/src/helper/playerImage"
import { AppState } from "@root/src/state"
import { Bet, BetType, Player, Team, TeamCode, TeamUid } from "@root/types"
import I18n from "i18n-js"
import _ from "lodash"
import React, { useRef, useState } from "react"
import ScrollContainer from "react-indiana-drag-scroll"
import { PlayerName } from "../Bet"
import { teamLogoUrl } from "@root/src/helper/teamLogo"
import { Row } from "../Ui"
import { useDisableBodyScrollWhenMounted } from "@root/src/helper/useDisableBodyScroll"
import { Input } from "@root/src/views/CompleteProfileView"
import { useHijackCloseIcon } from "../FixedCloseIcon"

export interface PickerEntry {
  item: Player,
  disabled?: boolean
}

export const TeamSelector: React.FC<{selected?: TeamCode, teams: Team[], doSelect(teamCode?: TeamCode): void}> = ({selected, teams, doSelect}) => {
  return <div className="neo__picker__teamSelector__holder">
    <div className="neo__picker__teamSelector">
      <a className={`neo__picker__teamSelector__item ${selected ? "" : "neo__picker__teamSelector__item--active"}`} onClick={() => doSelect()}>
        <span>{I18n.t("picker.team_filter.all--short")}</span>
      </a>
      {_.map(teams, (t) => {
        const active = t.team_code === selected
        return <a  className={`neo__picker__teamSelector__item ${active ? "neo__picker__teamSelector__item--active" : ""}`}
          key={t.team_code} onClick={() => doSelect(t.team_code)}>
            <img alt={t.name} src={teamLogoUrl(t.logo)} />
        </a>
      })}
    </div>
  </div>
}

export const PickerOverlay: React.FC<{bet: Bet, entries: PickerEntry[], teams: Team[], doPick(player: Player): void, doClose(): void}> = ({bet, doClose, doPick, entries, teams}) => {
  const [filter, __setFilter] = useState<{teamCode?: TeamCode, playerName?: string}>({})
  const [showAmount, setShowAmount] = useState<"all" | "reduced">("reduced")
  const [showTeamFilter, setShowTeamFilter] = useState(false)
  const ref = useRef<HTMLElement>()
  useDisableBodyScrollWhenMounted()

  useHijackCloseIcon({callback: doClose})

  const selectedTeamInFilter = _.find(teams, (t) => {
    return t.team_code === filter.teamCode
  })

  const setFilter = (teamCode?: TeamCode, playerName?: string) => {
    if (ref.current) { ref.current.scrollTo(0, 0); } // scroll to top
    if (playerName && playerName !== "") {
      if (showAmount === "reduced") { setShowAmount("all") }
    } else if (!teamCode && showAmount === "all") {
      setShowAmount("reduced") // show "reduced" when all teams
    }
    setShowTeamFilter(false)
    __setFilter({teamCode, playerName: playerName !== "" ? playerName: undefined})
  }

  const entriesToShow = _.chain(entries)
    .filter((e) => {
      let inFilter = true
      if (filter.teamCode) {
        if (e.item.team_code !== filter.teamCode) { inFilter = false }
      }
      if (filter.playerName && filter.playerName !== "") {
        inFilter = false
        if (e.item.nickname && e.item.nickname.toLowerCase().indexOf(filter.playerName.toLowerCase()) > -1) { inFilter = true}
        if (e.item.first_name && e.item.first_name.toLowerCase().indexOf(filter.playerName.toLowerCase()) > -1) { inFilter = true}
        if (e.item.last_name && e.item.last_name.toLowerCase().indexOf(filter.playerName.toLowerCase()) > -1) { inFilter = true}
      }
      return inFilter
    })
    .slice(0, showAmount === "reduced" ? 50 : undefined)
    .value()

  return <div className="neo__picker__overlay">
    <div className="neo__picker">      
      {false && <div className="neo__picker__closeIcon__holder">
        <div className="neo__picker__closeIcon" onClick={() => doClose()} />
      </div>}
      <div className="neo__picker__title">
        {I18n.t(`picker.${bet.config.type}`, {arg: bet.config.arg})}
      </div>
      <div className="neo__picker__nameFilterHolder">
        <Input
          disableAllAutoStuff
          placeholder={I18n.t("picker.name_filter.placeholder")}
          onChange={(val) => setFilter(undefined, val)}
          value={filter.playerName || ""}
          cancel={filter.playerName && filter.playerName !== ""
            ? () => setFilter()
            : undefined} />            
      </div>
      {showTeamFilter
        ? <TeamSelector teams={teams} selected={filter.teamCode} doSelect={setFilter}/>
        : <div className="neo__picker__selectedTeam" onClick={() => setShowTeamFilter(true)}>
            {filter.teamCode && selectedTeamInFilter
              ? <>
                  <img src={teamLogoUrl(selectedTeamInFilter.logo)} />
                  <span>{selectedTeamInFilter.name}</span>
                </>
              : <><span>{I18n.t("picker.team_filter.all")}</span></>}
          </div>}
      <div className="neo__picker__inner">
        <ScrollContainer ref={ref as any} mouseScroll={{overscroll: false, rubberBand: false}} hideScrollbars={false}>
          <div className="neo__picker__itemsHolder">
            {_.map(entriesToShow, (e, index) => {
              return <PickerEntryView key={e.item.id} points={e.item.bet_points[bet.config.type] || 0} entry={e} doPick={() => doPick(e.item)} />
            })}
            {showAmount === "reduced" && !filter.teamCode && entries.length > 50 &&
              <div className="neo__picker__showAllActionHolder">
                <a onClick={() => setShowAmount("all")}>SHOW ALL!</a>
              </div>}
          </div>
        </ScrollContainer>
      </div>
    </div>
  </div>
}

export const PickerEntryView: React.FC<{entry: PickerEntry, points: number, doPick(): void}> = ({entry, doPick, points}) => {
  const className = [
    "neo__picker__entry",
    entry.disabled ? "neo__picker__entry--disabled" : "neo__picker__entry--enabled"
  ].join(" ")

  return <div className={className} onClick={entry.disabled ? undefined : () => doPick()}>
    <div className="neo__picker__entry__imageSection">
      <div><PlayerImg player={entry.item} /></div>
    </div>
    <div className="neo__picker__entry__textSection">
      <PlayerName player={entry.item} />
      {entry.item.stats && <span className="neo__pickerEntry__playerStats">
          <span><span>{I18n.t("picker.stats_goals")}</span><strong>{entry.item.stats.goals || 0}</strong></span>
          <span><span>{I18n.t("picker.stats_assists")}</span><strong>{entry.item.stats.assists || 0}</strong></span>
        </span>}
    </div>
    <div className="neo__picker__entry__pointsSection">
      <span>{I18n.t("picker.label_points")}</span>
      <strong>{points}</strong>
    </div>    
  </div>
}
