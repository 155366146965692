import { ServerErrorResp, extractErrorMessage, isServerError } from "@root/types"
import { ActionForType, AppActionType, AppActions } from "../../action"
import { spawn, take } from "redux-saga/effects"
import { all, put, takeEvery, getContext, select } from "redux-saga/effects"
import _ from "lodash"
import { EMBEDDING_EVENT_DISPATCHER_CONTEXT_KEY, EmbediingEventDispatcherSagaContext } from "./sagaContext"

const MOBILE_HANDLER = "dflpick5"

const embeddingSend = function*(action: ActionForType<"embedding__send">) {
  const evt = new CustomEvent(action.data.message, {
    bubbles: true,
    composed: true,
    detail: action.data.payload || {},
  })

  const embeddingEventDispatcherContext: EmbediingEventDispatcherSagaContext = yield getContext(EMBEDDING_EVENT_DISPATCHER_CONTEXT_KEY)
  if (embeddingEventDispatcherContext.getEmbeddingEventDispatcher()) {
    embeddingEventDispatcherContext.getEmbeddingEventDispatcher().dispatchEvent(evt)
  }

  console.log("embeddingSend", evt)

  // iOS
  const iOSHandler = (window as any).webkit?.messageHandlers?.[MOBILE_HANDLER]
  if (iOSHandler) {
    const payload = { type: action.data.message, details: evt.detail }
    const json = JSON.stringify(payload)
    iOSHandler.postMessage(json)
  }
  // Android
  const AndroidHandler = (window as any)[MOBILE_HANDLER]
  if (AndroidHandler) {
    const payload = { type: evt.type, details: evt.detail }
    const json = JSON.stringify(payload)
    AndroidHandler.handleMessage(json)
  }
}

export default function* () {
  yield all([
    takeEvery<AppActionType>("embedding__send", embeddingSend),
  ])
}
