import { BootConfig, Buddy, DisplayError, FilterMode, Matchday, MeStats, RankingEntry, Story, User, UserId } from "@root/types"

export interface AppState {
  config: {
    initialized: boolean
    requesting: boolean
  } & BootConfig
  me: {
    initialized: boolean
    user?: User
    guest?: boolean
    needsToCompleteProfile?: boolean
    stats?: MeStats
  }
  matchdayView: {
    alreadyDisplayedTutorial?: boolean
    config?: {
      matchday: Matchday
      show_tutorial?: boolean
      is_next_matchday_active?: boolean
      next_matchday_opens_in_sec?: number
    }
    saveResult?: string
  }
  rankingView: {
    config?: {
      ranking_types : {name: string,id: string}[]
      ranking: {
        page: number, // starts at 1
        total_pages: number,
        entries: RankingEntry[]
        filter?: FilterMode
        ranking_type: string //id of the ranking type
      }      
    }
  },
  updateProfileView: {
    ok?: boolean
    error?: string
  },
  buddiesSearchView: {
    show: boolean
    config?: {
      page: number
      entries: User[]
      total_pages: number
    }
  }
  storyView: {
    show: boolean,
    userId?: UserId,
    swipeThroughUsersScope?: UserId[]
    config?: {
      story: Story
    }
  }
  landingView: {
    countdown?: { sec: number }
    next_matchday_opens_in_sec?: {sec: number}
  }
  seenStoriesFromUsers: {userId: UserId, seenMatchdays: string[]}[]
  completeProfileView: {
    error?: string
  }
  buddies: Buddy[]
  busy?: boolean
  error?: DisplayError
  locale: string
  authToken: string
  theme: String
  host: string
}

export const INIT_STATE: AppState = {
  config: {
    config_version: "",
    initialized: false,
    player_image_path: "",
    players: [],
    requesting: false,
    teams: []
  },
  seenStoriesFromUsers: [],
  storyView: {show: false},
  updateProfileView: {},
  buddiesSearchView: {show: false},
  completeProfileView: {},
  matchdayView: { alreadyDisplayedTutorial: false },
  rankingView: {},
  landingView: {},
  me: {initialized: false},
  buddies: [],
  authToken: "",
  locale: "en",
  theme: "light",
  host: ""
}
