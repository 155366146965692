import { extractErrorMessage, isServerError } from "@root/types"
import { AppActions } from "../../action"
import { AppState } from "../../state"
import _ from "lodash"

export default (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case "api__receive/story_view":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: extractErrorMessage(action.data.error), requiresReload: true}
        }
      } else {
        return {
          ...state,
          storyView: {
            ...state.storyView,
            config: {story: action.data.story}
          }
        }
      }
    case "story__clearConfig":
      return {
        ...state,
        storyView: {
          ...state.storyView,
          config: undefined
        }
      }
    case "story__showOverlay":
      return {
        ...state,
        storyView: {
          ...state.storyView,
          show: true,
          swipeThroughUsersScope: action.data.swipeThroughUsersScope,
          userId: action.data.userId
        }
      }
    case "story__hideOverlay":
      return {
        ...state,
        storyView: {
          ...state.storyView,
          swipeThroughUsersScope: undefined,
          show: false,
          userId: undefined
        }
      }
    case "story__markStoryOfUserSeen":
      const newSeenStories: AppState["seenStoriesFromUsers"] = _.cloneDeep(state.seenStoriesFromUsers)
      const index = _.findIndex(newSeenStories, (e) => e.userId === action.data.userId)
      if (index > -1) {
        // existing
        console.log("ex")
        if (newSeenStories[index].seenMatchdays.indexOf(action.data.matchday) === -1) {
          newSeenStories[index].seenMatchdays.push(action.data.matchday)
        }
       } else {
        // new
        newSeenStories.push({userId: action.data.userId, seenMatchdays: [action.data.matchday]})
      }
      return {
        ...state,
        seenStoriesFromUsers: newSeenStories
      }
  }
  return state
}
