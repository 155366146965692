import React, { PropsWithChildren, createContext, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Dispatch } from "@reduxjs/toolkit"
import { AppState, INIT_STATE } from "../state"
import { AppActions } from "../action"
import { UiActions } from "../action/uiActions"
import { useNavigate } from "react-router-dom"
import { IsWebViewContext } from "../helper/useIsWebView"
import _ from "lodash"

const AppContext = createContext<{state: AppState, dispatch(action: UiActions): void, navigate(path: string): void}>({state: INIT_STATE, dispatch() {}, navigate() {}})

export const ReduxAppContext: React.FC<PropsWithChildren> = ({children}) => {
  const state = useSelector((s: AppState) => s)
  const dispatch = useDispatch<Dispatch<UiActions>>()
  const navigate = useNavigate()

  return <AppContext.Provider value={{state, dispatch, navigate}}>
    <IsWebViewContext.Provider value={{isWebView: true}}>
      {children}
    </IsWebViewContext.Provider>
  </AppContext.Provider>
}

export const MockAppContext: React.FC<PropsWithChildren<{state: AppState, isWebView?: boolean}>> = ({children, state, isWebView}) => {
  const dispatch = (action: UiActions) => console.log(action.type, "data" in action ? action.data : "")
  const navigate = (path: string) => console.log("navigate", path)

  return <AppContext.Provider value={{state, dispatch, navigate}}>
    <IsWebViewContext.Provider value={{isWebView: _.isBoolean(isWebView) ? isWebView : true}}>
      {children}
    </IsWebViewContext.Provider>
  </AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)
