import React, { useContext, useEffect, useRef, useState } from "react"

const FixedCloseIconContext = React.createContext<{defaultClose(): void, hijackCloseIcon(close?: () => void): void}>({defaultClose: () => {}, hijackCloseIcon: () => {}})

const FixedCloseIcon: React.FC<{onClick(): void}> = ({onClick}) => {
  return <div className="neo__fixedCloseIcon__holder">
    <span className="neo__fixedCloseIcon" onClick={() => onClick()} />
  </div>
}

export const WithFixedCloseIcon: React.FC<React.PropsWithChildren<{defaultClose(): void}>> = ({defaultClose, children}) => {
  const hijacked = useRef<() => void>()

  return <FixedCloseIconContext.Provider value={{defaultClose, hijackCloseIcon: (x) => hijacked.current = x}}>
    {children}
    <FixedCloseIcon onClick={() => hijacked.current ? hijacked.current() : defaultClose()} />
  </FixedCloseIconContext.Provider>
}

export const useHijackCloseIcon = (props: {callback(): void}) => {
  const cont = useContext(FixedCloseIconContext)

  useEffect(() => {
    cont.hijackCloseIcon(props.callback)

    return () => { // cleanup
      cont.hijackCloseIcon()
    }
  }, [])
}

export default FixedCloseIcon
