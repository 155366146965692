import React, { useEffect, useState } from "react"

import imgSrc from "@images/keyVisual.png"
import imgSrcDark from "@images/keyVisual--dark.png"
import { useTheme } from "../../helper/WithTheme"
import { useAppContext } from "@root/src/context"
import _ from "lodash"
import { MeStat, MeStats } from "@root/types"
import I18n from "i18n-js"
import { useLocation } from "react-router-dom"
import routes from "@root/src/routes"
import Navi from "./Navi"
import useIsWebView from "@root/src/helper/useIsWebView"
import { BuddyAvatar } from "../Buddy"
import FixedCloseIcon from "../FixedCloseIcon"

const Stat: React.FC<{statKey: keyof MeStats, stat: MeStat}> = ({statKey, stat}) => {
  return <div className="neo__header__stat">
    <span>{I18n.t(`header.stat.${statKey}`)}:{" "}</span>
    <span>{stat.val}</span>
    {!_.isUndefined(stat.trend) && stat.trend !== "same" &&
      <span className={`neo__header__stat__trend neo__header__stat__trend--${stat.trend}`} />}
  </div>
}

const usePollMeEveryMillis = (millis: number) => {
  const {state, dispatch} = useAppContext()

  useEffect(() => {
    let interval: number

    if (state.me.user && !state.me.needsToCompleteProfile) {
      interval = window.setInterval(
        () => dispatch({type: "ui__requestMe"}),
        millis
      )
    }

    return () => { // cleanup
      if (_.isNumber(interval)) { window.clearInterval(interval) }
    }
  }, [!!state.me.user, !!state.me.needsToCompleteProfile, millis])
}

const Header: React.FC = () => {
  const {theme} = useTheme()
  const {state, dispatch, navigate} = useAppContext()
  const {isWebView} = useIsWebView()

  usePollMeEveryMillis(2 * 60 * 1000)

  const toUpdateProfile = () => navigate(routes.updateProfile())

  return <>
    <div className="neo__header">
      <img src={theme === "dark" ? imgSrcDark : imgSrc} />
    </div>
    {state.me?.user && !state.me.needsToCompleteProfile &&
      <div className="neo__header__statsSection">
        {state.me.user.label && !state.me.needsToCompleteProfile &&
          <div className="neo__header__nickname__holder">
            <div className="neo__header__nickname" onClick={toUpdateProfile}>
              <span><BuddyAvatar user={state.me.user} /></span>
              <span>{state.me.user.label}</span>
            </div>
          </div>}
        {state.me.stats &&
          <div className="neo__header__stats">
            <Stat statKey="weekly_rank" stat={state.me.stats.weekly_rank} />
            <Stat statKey="season_points" stat={state.me.stats.season_points} />
            <Stat statKey="season_rank" stat={state.me.stats.season_rank} />
          </div>}
      </div>}
  </>
}

export const HeaderNavi: React.FC = () => {
  const {state, navigate} = useAppContext()
  const location = useLocation()
  const onMatchday = location.pathname.indexOf(routes.matchday()) === 0
  const onLeaderboards = location.pathname.indexOf(routes.leaderboards()) === 0
  const onUpdateProfile = location.pathname.indexOf(routes.updateProfile()) === 0

  if (state.me && !state.me.needsToCompleteProfile && (onLeaderboards || onMatchday || onUpdateProfile)) {
    return <Navi items={[
        {
          title: I18n.t("header.navi.matchday"),
          onClick() { navigate(routes.matchday())},
          active: onMatchday
        },
        {
          title: I18n.t("header.navi.ranking"),
          onClick() { navigate(routes.leaderboards())},
          active: onLeaderboards
        }
      ]} />
  }
  return null
}

export default Header
