import React from "react"

import { Player, PlayerId } from "@root/types"
import { useTheme } from "./WithTheme"
import { useAppContext } from "../context"

import fallbackImg from "@images/playerImage__fallback.png"
import fallbackImgDark from "@images/playerImage__fallback.png"
import { useState } from "react"

export const usePlayerImage = () => {
  const { state } = useAppContext()

  return {
    playerImage(player: Player) {
      return (state.config.player_image_path || "").split("%{id}").join(player.external_uid)
    }
  }
}

const USE_FALLBACK_FOR: PlayerId[] = []

const __PlayerImg: React.FC<{player: Player}> = ({player}) => {
  const { theme } = useTheme()
  const { playerImage } = usePlayerImage()

  const [useFallback, setUseFallback] = useState(USE_FALLBACK_FOR.indexOf(player.id) > -1)
  const regularImage = playerImage(player)

  const onError = () => {
    if (USE_FALLBACK_FOR.indexOf(player.id) === -1) { USE_FALLBACK_FOR.push(player.id) }
    setUseFallback(true)
  }

  return <img src={useFallback ? (theme === "dark" ? fallbackImgDark : fallbackImg) : regularImage} onError={onError}  draggable={false} />
}

export const PlayerImg: React.FC<{player: Player}> = ({player}) => {
  return <__PlayerImg key={player.id} player={player} />
}
