import React from "react"

export const TinyPagination: React.FC<{page: number, total_pages: number, toPage(page: number): void}> = ({page, toPage, total_pages}) => {
  return <div className="neo__tinyPagination">
    {page > 1
      ? <a className="neo__tinyPagination__icon neo__tinyPagination__icon--prev" onClick={() => toPage(page - 1)} />
      : <span className="neo__tinyPagination__icon neo__tinyPagination__icon--prev" />}
    <span className="neo__tinyPagination__currentPage">{page}</span>
    {page < total_pages
      ? <a className="neo__tinyPagination__icon neo__tinyPagination__icon--next" onClick={() => toPage(page + 1)} />
      : <span className="neo__tinyPagination__icon neo__tinyPagination__icon--next" />}
  </div>
}
