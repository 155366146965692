import React, { useEffect } from "react"
import { useAppContext } from "../../context"

const BusyOverlay: React.FC = () => {
  const {state, dispatch} = useAppContext()

  return state.busy
    ? <div className="neo__ui__busyOverlay">
        <span />
      </div>
    : undefined
}

export default BusyOverlay
