import { ServerErrorResp, extractErrorMessage, isServerError } from "@root/types"
import { ActionForType, AppActionType, AppActions } from "../../action"
import { spawn, take } from "redux-saga/effects"
import { all, put, takeEvery, getContext, select } from "redux-saga/effects"
import _ from "lodash"
import { AppState } from "../../state"
import { ApiReq, ApiRequestBetPick, ApiRequestConfig, ApiRequestMarkScoringAsSeen, ApiRequestMatchdayGet, ApiRequestMatchdayGetCountdown, ApiRequestMe, ApiRequestMeCompleteProfile, ConfigVersionScope, actionTypes, apiPath } from "../../action/serverApiActions"
import { ApiRequestBuddiesAdd, ApiRequestBuddiesGet, ApiRequestBuddiesRemove, ApiRequestBuddiesSearch } from "./actions"

const getBuddies = function*(action: ActionForType<"buddies__get">) {
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/buddies_get"),
    data: {
    } as ApiReq<ApiRequestBuddiesGet>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/buddies_get")))
}

const addBuddy = function*(action: ActionForType<"buddies__add">) {
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/buddies_add_buddy"),
    data: {
      user_id: action.data.userId
    } as ApiReq<ApiRequestBuddiesAdd>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/buddies_add_buddy")))
}

const removeBuddy = function*(action: ActionForType<"buddies__remove">) {
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/buddies_remove_buddy"),
    data: {
      user_id: action.data.userId
    } as ApiReq<ApiRequestBuddiesRemove>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/buddies_remove_buddy")))
}

const requestSearch = function*(action: ActionForType<"buddies__requestSearch">) {
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/buddies_search"),
    data: {
      name: action.data.name,
      page: action.data.page
    } as ApiReq<ApiRequestBuddiesSearch>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/buddies_search")))
}

export default function* () {
  yield all([
    takeEvery<AppActionType>("buddies__get", getBuddies),
    takeEvery<AppActionType>("buddies__add", addBuddy),
    takeEvery<AppActionType>("buddies__remove", removeBuddy),
    takeEvery<AppActionType>("buddies__requestSearch", requestSearch),
  ])
}
