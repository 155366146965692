import React, { useEffect, useRef, useState } from "react"
import animateScrollTo from 'animated-scroll-to'

import img1 from "@images/landingTeaser__teaser--1.png"
import img2 from "@images/landingTeaser__teaser--2.png"
import I18n from "i18n-js"
import ScrollContainer from 'react-indiana-drag-scroll'
import _ from "lodash"

const SLIDES = [
  {
    img: img1,
    text: () => ""
  },
//  {
//    img: img2,
//    text: () => I18n.t("landing.prize2")
//  },
]

const LandingTeaser: React.FC = () => {
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true)
  const autoScrollEnabledRef = useRef(autoScrollEnabled)
  autoScrollEnabledRef.current = autoScrollEnabled
  const firstElementOffsetRef = useRef<number>()
  const [autoScrollToIndex, setAutoScrollToIndex] = useState(0)
  const refs = useRef<HTMLDivElement[]>([])
  const scrollContainerRef = useRef<HTMLDivElement>()

  useEffect(() => { // changeAutoscrollIndex
    let interval: number
    if (SLIDES.length <= 1) { return }
    if (!autoScrollEnabled) { return }
    interval = window.setInterval(() => {
      if (autoScrollEnabledRef.current) {
        setAutoScrollToIndex((prev) => {
          if (prev >= SLIDES.length - 1) {
            return 0
          }
          return prev + 1
        })
      } else {
        window.clearInterval(interval)
      }
    }, 4000)

    return () => { // cleanup
      if (_.isNumber(interval)) {
        window.clearInterval(interval)
      }
    }
  }, [])

  useEffect(() => {
    if (SLIDES.length <= 1) { return }
    if (autoScrollEnabled) {
      if (refs.current?.[autoScrollToIndex] && scrollContainerRef.current) {
        const targetX = refs.current[autoScrollToIndex].offsetLeft + scrollContainerRef.current.offsetLeft - refs.current[0].offsetLeft
        animateScrollTo(
          [targetX, 0],
          {
            elementToScroll: scrollContainerRef.current,
            minDuration: 1000,
            speed: 3000
          })
      }
    }
  }, [autoScrollEnabled, autoScrollToIndex])

  return <div className="neo__landingTeaser">
    <ScrollContainer ref={scrollContainerRef as any} mouseScroll={{overscroll: false, rubberBand: false}} hideScrollbars>
      <div className="neo__landingTeaser__inner" onMouseDown={() => {if (autoScrollEnabled) {setAutoScrollEnabled(false)}}}>
        {_.map(SLIDES, (s, index) => {
          return <div key={index} ref={(r) => refs.current[index] = r}>
            <img src={s.img} draggable={false} />
            <span>{s.text()}</span>
          </div>
        })}
      </div>
    </ScrollContainer>
  </div>
}

export default LandingTeaser
