import { useTheme } from "@root/src/helper/WithTheme"
import { AvatarImage, User } from "@root/types"
import _ from "lodash"
import React, { PropsWithChildren } from "react"

const avatarIconsLight = require.context("../../../images/dflAvatarImages/light", true, /\.(svg)$/)
const avatarIconsDark = require.context("../../../images/dflAvatarImages/dark", true, /\.(svg)$/)

export const BuddyAvatar: React.FC<{user: User}> = ({user}) => {
  return <div className="neo__buddy__avatar">
    <AvatarImageView image={user.image || {type: "icon", arg: DEFAULT_AVATAR_ICON}} />
  </div>
}

export const AVATAR_ICONS = _.chain(avatarIconsLight.keys())
  .filter(k => k.indexOf("./") === 0)
  .map(k => k.split("./").join("").split(".svg").join(""))
  .value()

export const DEFAULT_AVATAR_ICON = "soccerball"

export const AvatarImageView: React.FC<{image: AvatarImage}> = ({image}) => {
  const { theme } = useTheme()
  let src= ""
  if (image.type === "icon") {
    try {
      src = (theme === "dark" ? avatarIconsDark : avatarIconsLight)(`./${image.arg}.svg`)
    } catch(err) {}
  }
  return <img src={src} />
}

export const BuddyAvatarPlaceholder: React.FC = () => {
  return <div className="neo__buddy__avatar__placeholder" />
}

export const BuddyAddIcon: React.FC = () => {
  return <div className="neo__buddy__avatar__addIcon" />
}

export type BuddySurroundType =
  | "transparent"
  | "newStory"
  | "nothingNew"

export const BuddySurround: React.FC<PropsWithChildren<{onClick?(): void, type: BuddySurroundType}>> = ({children, type, onClick}) => {
  const className = [
    "neo__buddy__surround",
    `neo__buddy__surround--${type}`,
    onClick ? "neo__buddy__surround--active" : "neo__buddy__surround--inactive"
  ].join(" ")

  return <div className={className} onClick={onClick}>
    <div>
      {children}
    </div>
  </div>
}
