import { extractErrorMessage, isServerError } from "@root/types"
import { AppActions } from "../../action"
import { AppState } from "../../state"
import _ from "lodash"

export default (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case "buddies__showSearchPopup":
      return {
        ...state,
        buddiesSearchView: {...state.buddiesSearchView, show: true, config: undefined}
      }
    case "buddies__hideSearchPopup":
      return {
        ...state,
        buddiesSearchView: {...state.buddiesSearchView, show: false}
      }
    case "api__receive/buddies_search":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: extractErrorMessage(action.data.error), requiresReload: true}
        }
      } else {
        return {
          ...state,
          buddiesSearchView: {
            ...state.buddiesSearchView,
            config: action.data
          }
        }
      }
    case "api__receive/buddies_add_buddy":
    case "api__receive/buddies_get":
    case "api__receive/buddies_remove_buddy":
      if (isServerError(action.data)) {
        return {
          ...state,
          error: {error: extractErrorMessage(action.data.error), requiresReload: true}
        }
      } else {
        return {
          ...state,
          buddies: action.data.buddies
        }
      }
  }
  return state
}
