import I18n from "i18n-js"
import React, { useEffect, useState } from "react"
import { Panel, Row, Section } from "../components/Ui"
import LandingTeaser from "../components/LandingTeaser"
import Button from "../components/Ui/Button"
import { useNavigate } from "react-router-dom"
import routes from "../routes"
import { useAppContext } from "../context"
import MatchdayCountdown, { MatchdayCountdownPlaceholderWhileLoading } from "../components/MatchdayCountdown"
import useCountdown from "../helper/useCountdown"

const CountdownWrapperThatCountsDown: React.FC<{secLeft: number}> = ({secLeft}) => {
  const cnt = useCountdown({secondsLeft: secLeft})
  return <MatchdayCountdown {...cnt} />
}

const LandingView: React.FC = () => {
  const { state, navigate, dispatch } = useAppContext()

  const doStart = () => {
    if (!state.me.user) {
      dispatch({type: "displayError__set", data: {error: I18n.t("display_error.unauthorized_error"), requiresReload: true}})
    } else if (state.me.needsToCompleteProfile) {
      navigate(routes.completeProfile())
    } else {
      navigate(routes.matchday())
    }
  }

  useEffect(() => {
    dispatch({type: "ui__requestCountdownToNextMatchday"})
  }, [])

  return <>
    <Section style="hard">
      <Row>
        <Panel style="transparent" size="slim">
          <div className="neo__landing__intro">{I18n.t("landing.call_to_action")}</div>
        </Panel>
      </Row>
      <Row>
        <Panel style="transparent" size="wider">
          <LandingTeaser />
        </Panel>
      </Row>
      <Row>
        <div className="neo__landing__actionHolder">
          <Button type="primary" onClick={doStart}>{I18n.t("landing.action_start")}</Button>
        </div>
      </Row>
    </Section>
    <Section style="reduced">
      <Row>
        <div className="neo__landing__countdownHolder">
          <h3>{I18n.t("countdown.title")}</h3>
          {state.landingView.countdown
            ? <div>{I18n.t("countdown.next_match_starts_in")}</div>
            : state.landingView.next_matchday_opens_in_sec
              ? <div>{I18n.t("countdown.next_matchday_opens_in")}</div>
              : undefined}
          
          {state.landingView.countdown || state.landingView.next_matchday_opens_in_sec
            ? <CountdownWrapperThatCountsDown secLeft={state.landingView.countdown?.sec || state.landingView.next_matchday_opens_in_sec?.sec || 0}/>
            : <MatchdayCountdownPlaceholderWhileLoading />}
        </div>
      </Row>
    </Section>
  </>
}

export default LandingView
