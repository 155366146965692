import React, { useEffect, useRef } from "react"
import { Provider } from "react-redux"
import WithTheme, { Theme, useAddThemeToBody } from "./helper/WithTheme"
import LandingView from "./views/LandingView"
import I18n from "i18n-js"
import _ from "lodash"
import tmpBabel from "./tmpBabel.json"
import tmpBabel2 from "./tmpBabel2.json"
import { HashRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import RankingView from "./views/RankingView"
import MatchdayView from "./views/MatchdayView"
import { AppState, INIT_STATE } from "./state"
import { configureStore } from "@reduxjs/toolkit"
import { JSON_POST_API_CONTEXT_KEY, createSagaJSONPostApiContext } from "./helper/jsonPostApi"
import createSagaMiddleware from "redux-saga"
import reducer from "./reducer"
import mainSaga from "./saga"
import { ReduxAppContext, useAppContext } from "./context"
import routes from "./routes"
import DisplayErrorOverlay from "./components/DisplayErrorOverlay"
import BusyOverlay from "./components/BusyOverlay"
import Header, { HeaderNavi } from "./components/Header"
import { Section } from "./components/Ui"
import CompleteProfileView from "./views/CompleteProfileView"
import usePollForBuddies from "./logic/buddies/usePollForBuddies"
import { BuddySearchOverlay } from "./components/BuddySearch"
import StoryOverlay from "./components/StoryOverlay"
import { EMBEDDING_EVENT_DISPATCHER_CONTEXT_KEY, createEmbeddingEventDispatcherSagaContext } from "./logic/embedding/sagaContext"

import deTranslations from "../../../config/locales/de.json"
import enTranslations from "../../../config/locales/en.json"

import forceBabelRebuild from "./helper/forceBabelRebuild"
import useLockScreenOrientation from "./helper/useLockScreenOrientation"
import UpdateProfileView from "./views/UpdateProfileView"
import { WithFixedCloseIcon } from "./components/FixedCloseIcon"

export interface MountAppInit {
  host: string
  auth: string
  locale: string
  theme: Theme
}

let __I18nInitialized = false
export const initI18n = (locale: string) => {
  if (__I18nInitialized) { return }
  __I18nInitialized = true

  I18n.defaultLocale = locale
  I18n.locale = locale
  I18n.translations = _.merge({},
    {[locale]: tmpBabel["en"]},
    {[locale]: tmpBabel2["en"]},
    locale === "de" ? deTranslations : enTranslations)
}

const getStore = (createInit: () => AppState, embeddingEventDispatcher?: HTMLElement) => {
  const storeRef = useRef<ReturnType<typeof configureStore>>()

  if (!storeRef.current) {
    const context = {} as any
    const init = createInit()
    context[JSON_POST_API_CONTEXT_KEY] = createSagaJSONPostApiContext(init.authToken, init.locale)
    context[EMBEDDING_EVENT_DISPATCHER_CONTEXT_KEY] = createEmbeddingEventDispatcherSagaContext(embeddingEventDispatcher)
    const sagaMiddleware = createSagaMiddleware({ context })

    storeRef.current = configureStore({
      reducer: reducer,
      middleware: [sagaMiddleware],
      preloadedState: init
    })
    sagaMiddleware.run(mainSaga)
  }
  return storeRef.current
}
const AppInner: React.FC = () => {
  const { state, dispatch } = useAppContext()

  useEffect(() => {
    dispatch({ type: "ui__requestConfig" })
    dispatch({ type: "ui__requestMe" })
  }, [])

  useAddThemeToBody()
  usePollForBuddies(1000 * 60 * 2)
  useLockScreenOrientation("portrait")

  return <WithFixedCloseIcon defaultClose={() => dispatch({type: "embedding__send", data: {message: "close", payload: {}}})}>
    <Section style="hard">
      <ScrollHereWhenLocationChanges />
      <Header />
      <HeaderNavi />
      <DisplayErrorOverlay />
      {state.buddiesSearchView.show &&
        <BuddySearchOverlay doClose={() => dispatch({type: "buddies__hideSearchPopup"})}/>}
      {state.storyView.show &&
        <StoryOverlay doClose={() => dispatch({type: "story__hideOverlay"})}/>}
      <BusyOverlay />
    </Section>
    {state.config.initialized && state.me.initialized
      ? <>
          <Routes>
            <Route path={routes.landing()} Component={LandingView} />
            <Route path={routes.completeProfile()} Component={CompleteProfileView} />
            {!state.me.needsToCompleteProfile &&
              <>
                <Route path={routes.matchday()} Component={MatchdayView} />
                <Route path={routes.leaderboards()} Component={RankingView} />
                <Route path={routes.updateProfile()} Component={UpdateProfileView} />
              </>}
            <Route path="*" element={<Navigate to={routes.landing()} />} />
          </Routes>
        </>
      : <>
          {/* initial loading */}
        </>}
  </WithFixedCloseIcon>
}

const ScrollHereWhenLocationChanges: React.FC = () => {
  const location = useLocation()
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      window.scrollTo(0, rect.top)
    }
  }, [location.pathname])

  return <div ref={ref} />
}

const MountApp: React.FC<{init: MountAppInit, embeddingEventDispatcher?: HTMLElement}> = ({init, embeddingEventDispatcher}) => {
  initI18n(init.locale)
  const store = getStore(() => { return {...INIT_STATE, authToken: init.auth, theme: init.theme, locale: init.locale, host: init.host}}, embeddingEventDispatcher)

  return <WithTheme theme={init.theme} withBackground fullWindowHeight>
    <Provider store={store}>
      <HashRouter>
        <ReduxAppContext>
          <AppInner />
        </ReduxAppContext>
      </HashRouter>
    </Provider>
  </WithTheme>
}

export default MountApp
