import { Matchday, MatchdayState } from "@root/types"
import React from "react"
import useCountdown, { UnwrappedSec } from "./useCountdown"

export const useMatchdayState = (matchday: Matchday): {state: MatchdayState} & UnwrappedSec => {
  const cnt = useCountdown({secondsLeft: matchday.countdown_sec || 0})
  
  return {
    ...cnt,
    state: matchday.state === "open" && cnt.totalSecondsLeft <= 0
      ? "in_progress"
      : matchday.state
  }
}
