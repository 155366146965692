import { AvatarImage, Bet, BetId, BootConfig, Buddy, ConfigVersion, Matchday, MeStat, MeStats, PlayerId, RankingEntry, ServerErrorResp, User, UserId } from "@root/types"
import { AppActionType } from "."

export type ServerApiActions =
  | {type: "api__request", data: {action: string, data: any}}
  | {type: "api__request__error", data: {action: string, data: any, error: string}}
  | ApiAction<ApiRequestConfig>
  | ApiAction<ApiRequestMe>
  | ApiAction<ApiRequestMatchdayGetCountdown>
  | ApiAction<ApiRequestMatchdayGet>
  | ApiAction<ApiRequestBetPick>
  | ApiAction<ApiRequestMarkScoringAsSeen>
  | ApiAction<ApiRequestRankingGet>
  | ApiAction<ApiRequestMeCompleteProfile>

export type ApiSchema<requestUrl, Req = {}, Resp = {}, Scope extends ApiScope = {request: {}, response: {}}> = {
  url: requestUrl
  request: Req & Scope["request"]
  response: (Resp & Scope["response"]) | ServerErrorResp
}

export type ApiRequestConfig = ApiSchema<"api__receive/config",
  {
  },
  BootConfig,
  ConfigVersionScope
>

export type ApiRequestMe = ApiSchema<"api__receive/me",
  {
  },
  {
    user?: User 
    guest?: boolean
    needs_to_complete_profile?: boolean
    stats?: MeStats
  },
  ConfigVersionScope
>

export type ApiRequestMatchdayGetCountdown = ApiSchema<"api__receive/matchday_get_countdown",
  {
  },
  {
    countdown_sec?: number
    next_matchday_opens_in_sec?: number
  },
  ConfigVersionScope
>

export type ApiRequestMatchdayGet = ApiSchema<"api__receive/matchday_get",
  {
  },
  {
    matchday: Matchday
    show_tutorial?: boolean
    is_next_matchday_active?: boolean
  },
  ConfigVersionScope
>

export type ApiRequestBetPick = ApiSchema<"api__receive/bet_pick",
  {
    bet_id: BetId
    pick_id: PlayerId
  },
  {
    bet: Bet
    message: string
  },
  ConfigVersionScope
>
//vadym
export type ApiRequestRankingGet = ApiSchema<"api__receive/ranking_get",
  {
    ranking_type: string
    page?: number
    only_buddies: boolean
  },
  {
    ranking_types: {name: string, id: string}[]
    ranking: {
      ranking_type: string
      only_buddies?: boolean
      page: number
      total_pages: number
      entries: RankingEntry[]
    }
  },
  ConfigVersionScope
>

export type ApiRequestMeCompleteProfile = ApiSchema<"api__receive/me_complete_profile",
  {
    label: string
    image: AvatarImage
  },
  {
    ok: boolean
  },
  ConfigVersionScope
>

export type ApiRequestMarkScoringAsSeen = ApiSchema<"api__receive/matchday_mark_scoring_as_seen",
  {},
  {},
  ConfigVersionScope
>

export type ApiAction<Schema extends ApiSchema<Schema["url"]>> = {type: Schema["url"], data: ApiResp<Schema>}
export type ApiReq<Schema extends ApiSchema<Schema["url"]>> = Schema["request"]
export type ApiResp<Schema extends ApiSchema<Schema["url"]>> = Schema["response"]

type ApiScope<Req = {}, Resp = {}> = {
  request: Req
  response: Resp
}

export type ConfigVersionScope = ApiScope<
  {
  },
  {
    config_version: ConfigVersion
  }>

export const API_BASE = "api/v1"

export const apiPathWithoutBase = (full: string) => {
  if (full.indexOf(API_BASE) === 0) {
    return full.substring(API_BASE.length)
  }
  return full
}

export function actionTypes(...actionTypesArray: AppActionType[]): AppActionType[] { return actionTypesArray }

export const apiPath = (actionType: AppActionType) => {
  if (actionType.indexOf("api__receive") === 0) {
    return `${API_BASE}/${actionType.substring(13)}`
  }
  throw new Error(`actionType ${actionType} does not belong to an api response`)
}
  