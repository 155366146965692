import React from 'react'
import closeIcon from "@images/icon__close.png";
import closeIconDark from "@images/icon__close--dark.png";
import arrowTrendDownIcon from "@images/icon__trendDown.png";
import arrowTrendDownIconDark from "@images/icon__trendDown--dark.png";
import arrowTrendUpIcon from "@images/icon__trendUp.png";
import arrowTrendUpIconDark from "@images/icon__trendUp--dark.png";
import logoBundesleaga from "@images/keyVisual.png";
import logoBundesleagaDark from "@images/keyVisual--dark.png";
import friendIcon from "@images/icon__friend.png"
import friendIconDark from "@images/icon__friend--dark.png"
import legendIcon from "@images/icon__legend.png"
import legendIconDark from "@images/icon__legend--dark.png"
import { useAppContext } from '@root/src/context';

const Icon: React.FC<{imageSource: any, imageSourceDark: any, classname?: string}> = ({classname,imageSource, imageSourceDark}) => {
  const {state} = useAppContext()
  const {theme} = state
  const finalImageSource = theme === "dark" && imageSourceDark ? imageSourceDark : imageSource
  return (
    <div className="neo__icon__wrapper">
      <img src={finalImageSource} className={`neo__icon ${classname ? classname : ""}`} />
    </div>
  )
}

export const CloseIcon: React.FC = () => <Icon imageSource={closeIcon} imageSourceDark={closeIconDark} />
export const ArrowTrendDownIcon: React.FC = () => <Icon imageSource={arrowTrendDownIcon} classname="neo__icon--down" imageSourceDark={arrowTrendDownIconDark}/>
export const ArrowTrendUpIcon: React.FC = () => <Icon imageSource={arrowTrendUpIcon} imageSourceDark={arrowTrendUpIconDark} classname="neo__icon--up"/>
export const LogoBungesliga: React.FC = () => <Icon imageSource={logoBundesleaga} imageSourceDark={logoBundesleagaDark}/>
export const FriendIcon: React.FC = () => <Icon imageSource={friendIcon} imageSourceDark={friendIconDark}/>
export const LegendIcon: React.FC = () => <Icon imageSource={legendIcon} imageSourceDark={legendIconDark}/>
