import { ServerErrorResp, extractErrorMessage, isServerError } from "@root/types"
import { ActionForType, AppActionType, AppActions } from "../../action"
import { spawn, take } from "redux-saga/effects"
import { all, put, takeEvery, getContext, select } from "redux-saga/effects"
import _ from "lodash"
import { AppState } from "../../state"
import { ApiReq, ApiRequestBetPick, ApiRequestConfig, ApiRequestMarkScoringAsSeen, ApiRequestMatchdayGet, ApiRequestMatchdayGetCountdown, ApiRequestMe, ApiRequestMeCompleteProfile, ConfigVersionScope, actionTypes, apiPath } from "../../action/serverApiActions"
import { ApiRequestMeUpdateProfile } from "./actions"

const pollForUserAfterSave = function*(action: ActionForType<"api__receive/me_update_profile">) {
  if (!isServerError(action.data) && action.data.ok) {
    yield put<AppActions>({type: "ui__requestMe"})
  }
}

const sendForm = function*(action: ActionForType<"profile__update__sendForm">) {
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/me_update_profile"),
    data: {
      label: action.data.nickname,
      image: action.data.avatar
    } as ApiReq<ApiRequestMeUpdateProfile>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/me_update_profile")))
}

export default function* () {
  yield all([
    takeEvery<AppActionType>("profile__update__sendForm", sendForm),
    takeEvery<AppActionType>("api__receive/me_update_profile", pollForUserAfterSave),
  ])
}
