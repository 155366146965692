import { UnwrappedSec } from "@root/src/helper/useCountdown"
import I18n from "i18n-js"
import React from "react"

const MatchdayCountdown: React.FC<UnwrappedSec> = (unwrappedSec) => {
  return <div className="neo__matchday__countdown">
    {unwrappedSec.d !== "0" &&
      <span className="neo__matchday__countdown__val neo__matchday__countdown__val--d">
        <strong>{unwrappedSec.d}</strong>
        <span>{unwrappedSec.d === "1" ? I18n.t("countdown.label_d--one") : I18n.t("countdown.label_d--more")}</span>
      </span>}
    <span className="neo__matchday__countdown__val neo__matchday__countdown__val--hh"><strong>{unwrappedSec.hh}</strong></span>
    <span className="neo__matchday__countdown__separator">:</span>
    <span className="neo__matchday__countdown__val neo__matchday__countdown__val--mm"><strong>{unwrappedSec.mm}</strong></span>
    <span className="neo__matchday__countdown__separator">:</span>
    <span className="neo__matchday__countdown__val neo__matchday__countdown__val--ss"><strong>{unwrappedSec.ss}</strong></span>
  </div>
}

export const MatchdayCountdownPlaceholderWhileLoading: React.FC = () => {
  return <div className="neo__matchday__countdown__placeholderWhileLoadingHolder">
    <MatchdayCountdown d="0" hh="--" mm="--" ss="--" />
  </div>
}

export default MatchdayCountdown
