import React, { PropsWithChildren, useEffect, useState } from "react"
import _ from "lodash"

interface ButtonProps {
  type: "primary" | "secondary"
  href?: string
  onClick?(): void
  target?: string
  disabled?: boolean
  className?: string
}

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({type, className: passthroughClassName, target, children: Title, href, onClick, disabled}) => {
  const [hover, setHover] = useState(false)
  
  useEffect(() => { // remove hover state after a few sec
    let timeout: number
    if (hover) {
      timeout = window.setTimeout(() => setHover(false), 1000)
    }

    return () => { // cleanup
      if (_.isNumber(timeout)) { window.clearTimeout(timeout) }
    }
  }, [hover])

  const className = [
    "neo__button",
    `neo__button--${type}`,
    `neo__button--${hover ? "hovered" : "notHovered"}`,
    passthroughClassName || ""
  ].join(" ")

  const Inner = <span className="neo__button__inner">
    <span className="neo__button__title">{Title}</span>
  </span>

  return disabled
    ? <span className={className}>{Inner}</span>
    : <a {... {className, onClick, target, href}} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)} onPointerUp={() => setHover(false)}>{Inner}</a>
}

export default Button
