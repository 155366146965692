import { ServerErrorResp } from "@root/types"
import { ActionForType, AppActionType, AppActions } from "../action"
import { spawn, take } from "redux-saga/effects"
import { all, put, takeEvery, getContext, select } from "redux-saga/effects"
import _ from "lodash"
import { AppState } from "../state"
import { ConfigVersionScope, apiPath, apiPathWithoutBase } from "../action/serverApiActions"
import { JSONPostApiResp, JSONPostApiSagaContext, JSON_POST_API_CONTEXT_KEY } from "../helper/jsonPostApi"
import I18n from "i18n-js"

type ServerResponseEntry = {action: string} | ({action: string} & ServerErrorResp)

const handleServerResponse = function* (actionPath: string, r: ServerResponseEntry) {
  console.log(actionPath, r)
  // config up-to-date?
  const currentConfig: AppState["config"] = yield select((s: AppState) => s.config)
  if (
    _.isString(currentConfig.config_version)
    && currentConfig.config_version !== ""
    && _.isString(((r as unknown) as ConfigVersionScope["response"]).config_version)
    && currentConfig.config_version !== ((r as unknown) as ConfigVersionScope["response"]).config_version
    && r.action !== "/config"
    && !currentConfig.requesting
  ) {
    yield put<AppActions>({type: "ui__requestConfig"})
  }

  // throw api_receive action
  yield put<AppActions>({ type: `api__receive${apiPathWithoutBase(actionPath)}` as any, data: r as any})
}

const addScopeInfo = function*(req: any) {
  return req
}

const apiRequest = function*(action: ActionForType<"api__request">) {
  const api: JSONPostApiSagaContext = yield getContext(JSON_POST_API_CONTEXT_KEY)
  const host: string = yield select((s: AppState) => s.host)
  try {
    const postData: Object = yield addScopeInfo(action.data.data) as any
    console.log(action.data.action)
    const { data }: JSONPostApiResp<any> = yield api.post(
      host + action.data.action,
      postData
    )
    yield handleServerResponse(action.data.action, data)
  } catch(e) {
    yield put<AppActions>({type: "api__request__error", data: {...action.data, error: e}})
  }
}

const checkIfNotAuthorized = function*(action: ActionForType<"api__request__error">) {
  if ((action.data.error as any)?.response?.status === 401) {
    yield put<AppActions>({type: "displayError__set", data: {error: I18n.t("display_error.unauthorized_error"), requiresReload: true}})
  }
}

export default function* () {
  yield all([
    takeEvery<AppActionType>("api__request", apiRequest),
    takeEvery<AppActionType>("api__request__error", checkIfNotAuthorized),
  ])
}
