import { PlayerImg } from "@root/src/helper/playerImage"
import { Bet, Player } from "@root/types"
import I18n from "i18n-js"
import _ from "lodash"
import React from "react"

export interface BetsProps {
  canPick: boolean
  bets: Bet[]
  openPicker(bet: Bet): void
}

export const Bets: React.FC<BetsProps> = ({bets, openPicker, canPick}) => {
  return <div className="neo__bets">
    {_.map(bets, (b) => {
      return <BetView key={b.id} bet={b} disabled={!canPick} onClick={canPick ? () => openPicker(b) : undefined} />
    })}
  </div>
}

export const BetsPlaceholderWhileLoading: React.FC = () => {
  return <div className="neo__bets">
    {_.times(5, (t) => {
      return <div className="neo__betPlaceholder" key={t} />
    })}
  </div>
}

export const BetView: React.FC<{bet: Bet, disabled?: boolean, onClick?(): void}> = ({bet, disabled, onClick}) => {
  const className = [
    "neo__bet",
    disabled ? "neo__bet--disabled" : "neo__bet--enabled",
    bet.result ? `neo__bet--scored neo__bet--scored--${bet.result.correct ? "correct" : "wrong"}` : "neo__bet__notScored",
  ].join(" ")

  return <div className={className} onClick={disabled ? undefined : () => onClick()}>
    <div className="neo__bet__imageSection">
      {bet.pick
        ? <>
            <div className="neo__bet__playerImageHolder">
              <PlayerImg player={bet.pick} />
            </div>
          </>
        : <>
            {disabled
              ? <div className="neo__bet__placeholderImage" />
              : <div className="neo__bet__pickIcon" />}
          </>}
    </div>
    <div className="neo__bet__textSection">
      <div>
        <div>{I18n.t(`bet_type.${bet.config.type}`, {arg: bet.config.arg || 0})}</div>
        {bet.pick
          && <PlayerName player={bet.pick} />}
      </div>
    </div>
    <div className="neo__bet__pointsSection">
      <BetPoints
        points={bet.result
          ? bet.result.points
          : bet.pick?.bet_points[bet.config.type] || 0}
        variant={bet.result
          ? (bet.result.correct ? "correct" : "wrong")
          : "default"} />
    </div>
  </div>
}

export const PlayerName: React.FC<{player: Player}> = ({player}) => {
  return <span className="neo__bet__playerName">
    {player.nickname
      ? <strong>{player.nickname}</strong>
      : <><span>{player.first_name}</span>{" "}<strong>{player.last_name}</strong></>}
  </span>
}

export const BetPoints: React.FC<{points?: number, variant: "correct" | "wrong" | "default"}> = ({points, variant}) => {
  const className = [
    "neo__bet__points",
    `neo__bet__points--${variant}`,
  ].join(" ")

  return <div className={className}>
    <strong>{_.isNumber(points) ? I18n.t("type.points--short", {points}) : " "}</strong>
  </div>
}
