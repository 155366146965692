import { useAppContext } from "@root/src/context"
import _ from "lodash"
import React, { useEffect } from "react"

export default (everyMillis: number) => {
  const { state, dispatch } = useAppContext()

  useEffect(() => {
    let interval: number
    
    if (state.me.initialized && !state.me.needsToCompleteProfile) {
      interval = window.setInterval(() => {
        dispatch({type: "buddies__get"})
      }, everyMillis)

      dispatch({type: "buddies__get"})
    }

    return () => { // cleanup
      if (_.isNumber(interval)) { window.clearInterval(interval) }
    }
  }, [everyMillis, state.me.initialized, state.me.needsToCompleteProfile])
}
