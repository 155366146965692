import I18n from "i18n-js"
import React, { useEffect, useState } from "react"
import { useAppContext } from "../context"
import MatchdayHeader, { MatchdayHeaderSideItem } from "../components/MatchdayHeader"
import { Panel, Row, Section, SectionTitle } from "../components/Ui"
import { Bets, BetsPlaceholderWhileLoading } from "../components/Bet"
import { Bet, Buddy, Player, PlayerId, UserId } from "@root/types"
import { useMatchdayState } from "../helper/matchdayState"
import MatchdayCountdown, { MatchdayCountdownPlaceholderWhileLoading } from "../components/MatchdayCountdown"
import { BuddyAddIcon, BuddyAvatar, BuddyAvatarPlaceholder, BuddySurround } from "../components/Buddy"
import { PickerEntry, PickerOverlay } from "../components/Picker"
import _ from "lodash"
import Button from "../components/Ui/Button"
import Popup from "../components/Popup"
import useCountdown from "../helper/useCountdown"

export const SaveResult: React.FC<{message?: string, doClear(): void}> = ({message, doClear}) => {
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      doClear()
    }, 2000)

    return () => { // cleanup
      window.clearTimeout(timeout)
    }
  }, [message])

  return <div className="neo__saveResult__holder">
    {message &&
      <div className="neo__saveResult" key={message}>
        <span>{message}</span>
      </div>}
  </div>
}

export const buildPickerEntries = (bet: Bet, allPlayersInApp: Player[], alreadyPicked: PlayerId[]): PickerEntry[] => {
  return _.chain(allPlayersInApp)
    .filter((p) => (p.bet_points?.[bet.config.type] || 0) > 0) // all with points for this bet-type
    .map((p) => {
      return {
        item: p,
        disabled: alreadyPicked.indexOf(p.id) > -1 // already picked for other bet
      }
    })
    .value()
}

const buildBuddyEntries = (buddies: Buddy[], showStory: (userId: UserId) => void, alreadySawStory: (userId: UserId) => boolean): MatchdayHeaderSideItem[] => {
  return _.map(buddies, (b) => {
    return {
      key: b.user.id,
      image: <BuddySurround type={(b.has_new_story && !alreadySawStory(b.user.id)) ? "newStory" : "nothingNew"} onClick={() => showStory(b.user.id)}>
        <BuddyAvatar user={b.user} />
      </BuddySurround>,
      text: b.user.label || " "
    }
  })
}

const OpenInSecCountdown: React.FC<{initialSec: number}> = ({initialSec}) => {
  const {d, hh, mm} = useCountdown({secondsLeft: initialSec})
  return <>
    <span>{d}</span>
    {" "}
    {d === "1" ? I18n.t("countdown.label_d--one") : I18n.t("countdown.label_d--more")}
    {" "}
    <span>{hh}</span>
    {":"}
    <span>{mm}</span>
  </>
}

const __MatchdayView: React.FC = () => {
  const {state, dispatch} = useAppContext()
  const matchday = state.matchdayView.config.matchday
  const matchdayState = useMatchdayState(matchday)
  const [pickerConf, setPickerConf] = useState<{
    bet: Bet,
    entries: PickerEntry[]
  }>()

  const canEdit = matchdayState.state === "open"

  const openPicker = (bet: Bet) => {
    const alreadyPickedForThisBetType = _.chain(matchday.bets)
      .filter((b) => { return b.id !== bet.id && b.config.type === bet.config.type && !!b.pick }) // other bets of same type with picks
      .map(b => b.pick.id)
      .value()

    setPickerConf({
      bet,
      entries: buildPickerEntries(bet, state.config.players, alreadyPickedForThisBetType)
    })
  }

  const clearSaveResult = () => dispatch({type: "ui__clearBetSaveResult"})

  const savePick = canEdit
    ? (bet: Bet, pick: Player) => dispatch({type: "ui__makePick", data: {bet: bet.id, pick: pick.id}})
    : () => {}

  const totalMatchdayPoints = _.reduce(matchday.bets, (res, b) => {return res + ((b.result?.correct && b.result?.points) || 0) }, 0)

  const showButtonToNextMatchday = matchdayState.state === "scored" && state.matchdayView.config.is_next_matchday_active
  const showCountdownToWhenNextMatchdayOpens = matchdayState.state === "scored" && !showButtonToNextMatchday && (state.matchdayView.config.next_matchday_opens_in_sec || 0) > 0

  return <>
    {pickerConf &&
      <PickerOverlay
        teams={state.config.teams}
        bet={pickerConf.bet}
        entries={pickerConf.entries}
        doClose={() => setPickerConf(undefined)}
        doPick={(player) => {
          savePick(pickerConf.bet, player)
          setPickerConf(undefined)
        }} />}
    {state.matchdayView.config.show_tutorial && !state.matchdayView.alreadyDisplayedTutorial && matchdayState.state === "open" &&
      <Popup
        title={I18n.t("tutorial.title")}
        onClose={() => dispatch({type: "ui__markMatchdayTutorialAsSeen"})}
        closeLabel={I18n.t("tutorial.action_start")}>
          {I18n.t("tutorial.info")}</Popup>}
    <Section style="reduced" fullWidth>
      <MatchdayHeader
        title={I18n.t("matchday.title", {title: matchday.title})}
        subtitle={matchday.subtitle}
        sideItems={[
          {
            key: "invite",
            image: <BuddySurround type="transparent" onClick={() => dispatch({type: "buddies__showSearchPopup"})}><BuddyAddIcon /></BuddySurround>,
            text: I18n.t("buddy.action_invite--short")
          },
          ...buildBuddyEntries(
            state.buddies,
            (userId) => dispatch({type: "story__showOverlay", data: {userId, swipeThroughUsersScope: _.map(state.buddies, b => b.user.id)}}),
            (userId) => {
              const conf = state.matchdayView.config
              if (!conf) { return false }

              const u = _.find(state.seenStoriesFromUsers, (s) => s.userId === userId)
              if (u) {
                return u.seenMatchdays.indexOf(`${conf.matchday.id}`) > -1
              }
            }
          )
        ]}/>
    </Section>
    {showButtonToNextMatchday && <>
      <Row>
        <Panel style="transparent" size="slim">
          <div className="neo__matchday__actionTextinfoHolder">
            <p>{I18n.t("matchday.scored.info_above_pick_next_action")}</p>
            <a onClick={() => dispatch({type: "ui__markMatchdayScoringAsSeenAndPollCurrentMatchday"})}>{I18n.t("matchday.scored.action_to_next_matchday")}</a>
          </div>
        </Panel>
      </Row>
    </>}
    <Section style="reduced">
      <Panel size="slim" style={matchdayState.state === "scored" ? "card" : "transparent"}>
        {matchdayState.state === "scored"
          ? <>{/* when scored: show result points + congrats */}
              <Panel style="transparent" size="slim">
                <Row>
                  <SectionTitle>
                    {I18n.t(totalMatchdayPoints > 0
                      ? "matchday.scored.title--when_points"
                      : "matchday.scored.title--when_no_points")}
                  </SectionTitle>
                </Row>
                <Row>
                  <div className="neo__matchday__scoredInfoHolder">
                    {I18n.t("matchday.scored.info", {matchday: matchday.title, points: totalMatchdayPoints})}
                  </div>
                </Row>
              </Panel>
            </>
          : <>{/* when not scored: countdown */}
              <Row>
                <div className="neo__matchday__countdownHolder">
                  <div>{I18n.t("countdown.title--short")}</div>
                  <MatchdayCountdown {...matchdayState} />
                </div>
              </Row>
            </>}
        {showCountdownToWhenNextMatchdayOpens && <>
          <Row>
            <Panel style="transparent" size="slim">
              <div className="neo__matchday__nextOpenInSec">
                <p>{I18n.t("matchday.scored.next_matchday_open_in")}</p>
                <p>
                  <strong><OpenInSecCountdown initialSec={state.matchdayView.config?.next_matchday_opens_in_sec || 0} /></strong>
                </p>                
              </div>
            </Panel>
          </Row>
        </>}
        <Row>
          <Panel size="slim" style="transparent">
            <Bets bets={matchday.bets} canPick={canEdit} openPicker={openPicker} />
          </Panel>
          {canEdit &&
            <SaveResult doClear={clearSaveResult} message={state.matchdayView.saveResult} />}
        </Row>
        {matchdayState.state === "scored" && <>
            <Row>
              <Panel style="transparent" size="slim">
                <div className="neo__matchday__seasonPointsInfoHolder">
                  {I18n.t("matchday.scored.info_season_points", {points: totalMatchdayPoints})}
                </div>
              </Panel>
            </Row>
          </>}
      </Panel>
      {showButtonToNextMatchday && <>
        <Row>
          <Panel style="transparent" size="slim">
            <div className="neo__matchday__actionHolder">
              <Button
                type="primary"
                onClick={() => dispatch({type: "ui__markMatchdayScoringAsSeenAndPollCurrentMatchday"})}>
                  {I18n.t("matchday.scored.action_to_next_matchday")}
              </Button>
            </div>
          </Panel>
        </Row>
      </>}
      {!state.matchdayView.config.is_next_matchday_active && <>
        <Row>
          <Panel style="transparent" size="slim">
            <div className="neo__matchday__actionHolder">
              <Button type="primary" onClick={() => dispatch({
                type: "embedding__send",
                data: {
                  message: "share",
                  payload: {text: I18n.t("share.on_matchday.text"), url: I18n.t("share.on_matchday.url")}}})}>{I18n.t("matchday.action_share")}</Button>
            </div>
          </Panel>
        </Row>
      </>}
    </Section>
  </>
}

const __LoadingPlaceholder: React.FC = () => {
  const {state, dispatch} = useAppContext()

  return <>
    <Section style="reduced" fullWidth>
      <MatchdayHeader
        title={I18n.t("matchday.title_while_loading")}
        subtitle={I18n.t("matchday.subtitle_while_loading")}
        sideItems={buildBuddyEntries(state.buddies, (userId) => dispatch({type: "story__showOverlay", data: {userId}}), () => false)} />
    </Section>
    <Section style="reduced">   
      <Row>
        <div className="neo__matchday__countdownHolder">
          <div>{I18n.t("countdown.title--short")}</div>
          <MatchdayCountdownPlaceholderWhileLoading />
        </div>
      </Row>
      <Row>
        <Panel size="slim" style="transparent">
          <BetsPlaceholderWhileLoading />
        </Panel>
      </Row>
    </Section>
  </>
}

const MatchdayView: React.FC = () => {
  const {state, dispatch} = useAppContext()

  useEffect(() => {
    dispatch({type: "ui__requestMatchday", data: {clearPreviousMatchday: true}})

    const interval = window.setInterval(() => {
      dispatch({type: "ui__requestMatchday", data: {clearPreviousMatchday: false}})
    }, 1000 * 60)

    return () => { //cleanup
      clearInterval(interval)
    }
  }, [])

  const matchday = state.matchdayView.config?.matchday

  return matchday
    ? <__MatchdayView key={matchday.id} />
    : <__LoadingPlaceholder />
}

export default MatchdayView
