import { PlayerImg } from "@root/src/helper/playerImage"
import { AppState } from "@root/src/state"
import { Bet, BetType, Player, Team, TeamCode, TeamUid, User } from "@root/types"
import I18n from "i18n-js"
import _ from "lodash"
import React, { useEffect, useRef, useState } from "react"
import ScrollContainer from "react-indiana-drag-scroll"
import { PlayerName } from "../Bet"
import { teamLogoUrl } from "@root/src/helper/teamLogo"
import { Row } from "../Ui"
import { useAppContext } from "@root/src/context"
import { AvatarImageView } from "../Buddy"
import Button from "../Ui/Button"
import { Input } from "@root/src/views/CompleteProfileView"
import { TinyPagination } from "../TinyPagination"
import { useDisableBodyScrollWhenMounted } from "@root/src/helper/useDisableBodyScroll"
import { useHijackCloseIcon } from "../FixedCloseIcon"

export const BuddySearchOverlay: React.FC<{doClose(): void}> = ({doClose}) => {
  const [filter, setFilter] = useState<{name: string}>({name: ""})
  const ref = useRef<HTMLElement>()
  const {state, dispatch} = useAppContext()
  const debouncedDispatchSearch = useRef(_.debounce((f: typeof filter) => {
    dispatch({type: "buddies__requestSearch", data: f})
  }, 300))

  useHijackCloseIcon({callback: doClose})

  useDisableBodyScrollWhenMounted()

  useEffect(() => {
    dispatch({type: "buddies__requestSearch", data: {name: ""}})
  }, [])

  const submitFilter = (alsoSetNewFilter?: typeof filter) => {
    if (ref.current) { ref.current.scrollTo(0, 0); } // scroll to top
    if (alsoSetNewFilter) { setFilter(alsoSetNewFilter) }
    debouncedDispatchSearch.current(alsoSetNewFilter || filter)
  }

  return <div className="neo__buddySearch__overlay">
    <div className="neo__buddySearch">
      {false &&
        <div className="neo__buddySearch__closeIcon__holder">
          <div className="neo__buddySearch__closeIcon" onClick={() => doClose()} />
        </div>}
      <div className="neo__buddySearch__title">
        {I18n.t("buddy_search.title")}
      </div>
      <div className="neo__buddySearch__inputHolder">
        <Input
          disableAllAutoStuff
          value={filter.name}
          label={I18n.t("buddy_search.label_name")}
          placeholder={I18n.t("buddy_search.placeholder_name")}
          onChange={(val) => submitFilter({name: val})}
          cancel={filter.name !== ""
            ? () => submitFilter({name: ""})
            : undefined}/>
      </div>
      <div className="neo__buddySearch__inner">
        <ScrollContainer ref={ref as any} mouseScroll={{overscroll: false, rubberBand: false}} hideScrollbars={false}>
          <div className="neo__buddySearch__itemsHolder">
            {state.buddiesSearchView.config && state.buddiesSearchView.config.entries.length === 0 &&
              <div className="neo__buddySearch__noEntriesInfoHolder">
                <span>{I18n.t("buddy_search.no_entries")}</span>
              </div>}
            {_.map(state.buddiesSearchView.config?.entries || [], (entry, index) => {
              const isBuddy = _.some(state.buddies || [], (b) => b.user.id === entry.id)

              return <div className="neo__buddySearch__entry" key={entry.id}>
                <div className="neo__buddySearch__entry__imageSection">
                  <div>{entry.image && <AvatarImageView image={entry.image} />}</div>
                </div>
                <div className="neo__buddySearch__entry__textSection">
                  <div><strong className="neo__usernameMax2lines">{entry.label}</strong></div>
                </div>
                <div className="neo__buddySearch__entry__actionSection">
                  {isBuddy
                    ? <span className="neo__buddySearch__entry__alreadyBuddy" />
                    : <Button type="secondary" onClick={() => dispatch({type: "buddies__add", data: {userId: entry.id}})}>{I18n.t("buddy_search.action_add")}</Button>}                  
                </div>    
              </div>
            })}
            {state.buddiesSearchView.config && state.buddiesSearchView.config.total_pages > 1 &&
              <div className="neo__buddySearch__paginationHolder">
                <TinyPagination {...state.buddiesSearchView.config} toPage={(page) => dispatch({type: "buddies__requestSearch", data: {name: filter.name, page: page}})} />
              </div>}
          </div>
        </ScrollContainer>
      </div>
      <div className="neo__buddySearch__actionArea">
        <Button type="primary" onClick={() => dispatch({
          type: "embedding__send",
          data: {
            message: "share",
            payload: {text: I18n.t("share.on_buddy_search.text"), url: I18n.t("share.on_buddy_search.url")}}})}>{I18n.t("buddy_search.action_share")}</Button>
      </div>
    </div>
  </div>
}
