import { useEffect } from "react"

const useLockScreenOrientation = (orientation: "landscape" | "portrait") => {
  useEffect(() => {
    if (screen.orientation) {
      try {
        ;(screen.orientation as any)
          .lock(orientation)
          .then(() => {            
          })
          .catch((err: any) => {
            console.log("could not lock orientation", err)
          })
      } catch(err) {
        console.log("could not lock orientation", err)
      }      
    }
  }, [orientation])
}

export default useLockScreenOrientation
