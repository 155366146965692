import React, { PropsWithChildren, ReactNode } from "react"
import { Panel } from "../Ui"
import _ from "lodash"
import ScrollContainer from "react-indiana-drag-scroll"

export interface MatchdayHeaderSideItem {
  key: any
  image: ReactNode
  text: string
}

const MatchdayHeader: React.FC<{title: string, subtitle: string, sideItems?: MatchdayHeaderSideItem[]}> = ({sideItems, title, subtitle}) => {
  return <div className="neo__matchday__header__holder">
    <Panel size="wider" style="transparent">
      <div className="neo__matchday__header">
        <div className="neo__matchday__header__titleSection">
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
        </div>
        <div className="neo__matchday__header__sideSection">
          <ScrollContainer mouseScroll={{overscroll: false, rubberBand: false}}>
            <div className="neo__matchday__header__sideSection__inner">
              {_.map(sideItems || [], (s) => {
                return <div key={s.key}>
                  <span>{s.image}</span>
                  <span>{s.text}</span>
                </div>
              })}
            </div>
          </ScrollContainer>
        </div>
      </div>
    </Panel>
  </div>
}

export default MatchdayHeader
