import { AppActions } from "../action"
import { AppState } from "../state"

export default (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case "ui__clearBetSaveResult":
      return {
        ...state,
        matchdayView: {
          ...state.matchdayView,
          saveResult: undefined
        }
      }
    case "ui__setCompleteProfileError":
      return {
        ...state,
        completeProfileView: {
          ...state.completeProfileView,
          error: action.data.error
        }
      }
    case "ui__markMatchdayTutorialAsSeen":
      return {
        ...state,
        matchdayView: {
          ...state.matchdayView,
          alreadyDisplayedTutorial: true
        }
      }
  }
  return state
}
