import React, { useEffect } from "react"
import I18n from "i18n-js"
import { useAppContext } from "../../context"
import routes from "@root/src/routes"

const DisplayErrorOverlay: React.FC = () => {
  const {state, dispatch, navigate} = useAppContext()

  const handleError = () => {
    if (!state.error) { return }
    if (state.error.requiresReload) {
      dispatch({type: "embedding__send", data: {message: "close", payload: {}}})
      // navigate(routes.landing())
      // window.location.reload()
    } else {
      dispatch({type: "ui__clearDisplayError"})
    }
  }

  return state.error
    ? <div className="neo__ui__displayError__overlay">
        <div>
          <p>{state.error.error}</p>
          <p>
            <a onClick={() => handleError()}>{I18n.t("display_error.action_ok")}</a>
          </p>
        </div>
      </div>
    : undefined
}

export default DisplayErrorOverlay
