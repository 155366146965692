import _ from "lodash"
import React from "react"

export interface NaviItem {
  title: string
  active?: boolean
  onClick(): void
}

const Navi: React.FC<{items: NaviItem[]}> = ({items}) => {
  return <div className="neo__header__navi">
    {_.map(items, (item, index) => {
      const className = [
        "neo__header__navi__item",
        item.active ? "neo__header__navi__item--active" : "neo__header__navi__item--inactive",
      ].join(" ")
      return <div key={index} className={className} onClick={() => item.onClick()}>
        <span>{item.title}</span>
      </div>
    })}
  </div>
}

export default Navi
