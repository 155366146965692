import React, { PropsWithChildren } from "react"
import {SeparatorLine, SeparatorLineVertical} from "../Ui"
import {RankingEntry, UserId} from "@root/types"
import I18n from "i18n-js"
import {FriendIcon, LegendIcon} from "../Ui/Icons"
import _ from "lodash"
import {BuddyAvatar, BuddyAvatarPlaceholder, BuddySurround} from "../Buddy"
import { useAppContext } from "@root/src/context"

export const RankingEntriesHolder: React.FC<PropsWithChildren> = ({children}) => <div className="neo__ranking__entriesHolder">{children}</div>

export const RankingEntryView: React.FC<{item?: RankingEntry, isOwn?: boolean, userIcon?: NameIconProps["type"], matchdayId?: string}> = ({item, isOwn, userIcon, matchdayId}) => {
	const {dispatch} = useAppContext()

	const className = [
		"neo__ranking__entry",
		isOwn ? "neo__ranking__entry--own" : "",
		item ? "neo__ranking__entry--filled" : "neo__ranking__entry--empty"
	].join(" ")

	const showStory = item
		? () => dispatch({type: "story__showOverlay", data: {userId: item.user.id, matchday: matchdayId || undefined}})
		: () => {}

	return <div className={className}>
		<div className="neo__ranking__entry__rankHolder">{item ? item.rank : undefined}</div>
		<div className="neo__ranking__entry__avatarHolder">
			{item
				? <BuddySurround type="transparent" onClick={() => showStory()}><BuddyAvatar user={item.user} /></BuddySurround>
				: undefined}</div>
		<div className="neo__ranking__entry__nameHolder">
			{item
				? <>
						<span onClick={() => showStory()}>{item.user.label}</span>
						{userIcon && <NameIcon type={userIcon} />}
					</>
				: <></>}
		</div>
		<div className="neo__ranking__entry__scoreHolder"><span>{item ? I18n.t("ranking.points", {points: item.score}) : undefined}</span></div>
	</div>
}

type NameIconProps = {
	type: "friend" | "expert" | "none"
}

const NameIcon: React.FC<NameIconProps> = ({type}) => {
	if (type === "none") {
		return <div className="neo__ranking__name__icon__wrapper"></div>
	}
	const icon = type === "friend" ? <FriendIcon /> : <LegendIcon />
	return <div className="neo__ranking__name__icon__wrapper">{icon}</div>
}
