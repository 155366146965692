import React, { PropsWithChildren, useEffect } from "react"
import I18n from "i18n-js"
import { useAppContext } from "../../context"
import routes from "@root/src/routes"
import Button from "../Ui/Button"

interface PopupProps {
  onClose(): void
  closeLabel: string
  title?: string
}

const Popup: React.FC<PropsWithChildren<PopupProps>> = ({closeLabel, onClose, title, children}) => {
  return <div className="neo__ui__popup__overlay">
    <div>
      {title && <h2>{title}</h2>}
      <p>{children}</p>
      <p>
        <Button type="primary" onClick={() => onClose()}>{closeLabel}</Button>
      </p>
    </div>
  </div>
}

export default Popup
