import { Buddy, FilterMode } from "@root/types"
import I18n from "i18n-js"
import React, { useState } from "react"

type RankingType = {
  id: string,
  name: string
}

interface MatchdayDropdownProps {
  items: RankingType[]
  activeItem: RankingType
  onChange: (item: RankingType) => void
}

const MatchdayDropdown: React.FC<MatchdayDropdownProps> = ({
  items,
  activeItem,
  onChange
}) => {
  const onChangeHandler = (itemId: string) => {
    if (itemId === activeItem.id) {
      return
    }
    const item = items.find( el => el.id === itemId)
    if (!item) {
      return
    }
    onChange(item)
  }


  return (
    <div className="neo__dropdown__container">
      <select 
        id="custom__dropdown__match" 
        onChange={(e)=> onChangeHandler(e.target.value)} 
        value={activeItem.id} 
        className="neo__dropdown__select"
      >
        {items.map((item, index) => {
          return (
            <option className="neo__dropdown__option" key={item.name} value={item.id} defaultValue={activeItem.id}>
              {item.name}
            </option>
          )
          })}
      </select>
      <div className="neo__dropdown__icon__container">
        <div className="neo__dropdown__icon"></div>
      </div>
    </div>
  )
}

interface FriendsDropdownProps {
  activeItem: "experts" | "buddies" | "all" 
  onChange: (item: string) => void
}
const getTranslationKey = (activeItem: string): string => {
  switch (activeItem) {
    case "buddies":
      return "ranking.buddies_type_buddies_only"
    case "experts":
      return "ranking.buddies_type_experts"
    default:
      return "ranking.buddies_type_all"
  }
}

export const FriendsDropdown: React.FC<FriendsDropdownProps> = ({
  activeItem,
  onChange
}) => {
  const OPTIONS = ["all" , "buddies" /* , "experts" */]
  const onChangeHandler = (item: string) => {
    if (item === activeItem) {
      return
    }
    onChange(item)
  }

  return (
    <div className="neo__dropdown__container">
      <select 
        id="custom-dropdown" 
        onChange={(e)=> onChangeHandler(e.target.value)} 
        value={activeItem} 
        className="neo__dropdown__select"
      >
        <option className="neo__dropdown__option" value={activeItem}>{I18n.t(getTranslationKey(activeItem))}</option>
        {OPTIONS.map((item, index) => {
          if (item === activeItem) {
            return null 
          }
          return (
            <option className="neo__dropdown__option" key={item+index} value={item}>
             {I18n.t(getTranslationKey(item))}
            </option>
          )
          })}
      </select>
      <div className="neo__dropdown__icon__container">
        <div className="neo__dropdown__icon"></div>
      </div>
    </div>
  )
}

export default MatchdayDropdown

