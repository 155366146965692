import { ServerErrorResp, extractErrorMessage, isServerError } from "@root/types"
import { ActionForType, AppActionType, AppActions } from "../action"
import { spawn, take } from "redux-saga/effects"
import { all, put, takeEvery, getContext, select } from "redux-saga/effects"
import _ from "lodash"
import { AppState } from "../state"
import { ApiReq, ApiRequestBetPick, ApiRequestConfig, ApiRequestMarkScoringAsSeen, ApiRequestMatchdayGet, ApiRequestMatchdayGetCountdown, ApiRequestMe, ApiRequestRankingGet, ApiRequestMeCompleteProfile, ConfigVersionScope, actionTypes, apiPath } from "../action/serverApiActions"
import { JSONPostApiResp, JSONPostApiSagaContext, JSON_POST_API_CONTEXT_KEY } from "../helper/jsonPostApi"

const requestConfig = function*(action: ActionForType<"ui__requestConfig">) {
  yield put<AppActions>({type: "busy__setBusy"})
  yield put<AppActions>({type: "maintenance__markConfigRequesting"})
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/config"),
    data: {

    } as ApiReq<ApiRequestConfig>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/config")))
  yield put<AppActions>({type: "busy__setNotBusy"})
}

const requestRankingGet = function*(action: ActionForType<"ui__rankingGet">) {
  yield put<AppActions>({type: "busy__setBusy"})
  yield put<AppActions>({type: "maintenance__clearRankings"})
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/ranking_get"),
    data: {
      ...action.data
    } as ApiReq<ApiRequestRankingGet>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/ranking_get")))

  yield put<AppActions>({type: "busy__setNotBusy"})
}

const requestMe = function*(action: ActionForType<"ui__requestMe">) {
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/me"),
    data: {

    } as ApiReq<ApiRequestMe>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/me")))
}

const requestCountdownToNextMatchday = function*(action: ActionForType<"ui__requestCountdownToNextMatchday">) {
  yield put<AppActions>({type: "maintenance__clearLandingCountdown"})
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/matchday_get_countdown"),
    data: {

    } as ApiReq<ApiRequestMatchdayGetCountdown>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/matchday_get_countdown")))
}

const requestMatchday = function*(action: ActionForType<"ui__requestMatchday">) {
  if (action.data.clearPreviousMatchday) {
    yield put<AppActions>({type: "maintenance__clearMatchday"})
  }
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/matchday_get"),
    data: {

    } as ApiReq<ApiRequestMatchdayGet>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/matchday_get")))
}

const requestMakePick = function*(action: ActionForType<"ui__makePick">) {
  yield put<AppActions>({type: "busy__setBusy"})
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/bet_pick"),
    data: {
      bet_id: action.data.bet,
      pick_id: action.data.pick
    } as ApiReq<ApiRequestBetPick>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/bet_pick")))
  yield put<AppActions>({type: "busy__setNotBusy"})
}

const sendCompleteProfileForm = function*(action: ActionForType<"ui__sendCompleteProfileForm">) {
  yield put<AppActions>({type: "busy__setBusy"})
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/me_complete_profile"),
    data: {
      label: action.data.nickname,
      image: action.data.avatar
    } as ApiReq<ApiRequestMeCompleteProfile>
  }})
  const res: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/me_complete_profile")))
  if (res.type === "api__receive/me_complete_profile") {
    if (isServerError(res.data)) {
      yield put<AppActions>({type: "ui__setCompleteProfileError", data: {error: extractErrorMessage(res.data.error)}})
    } else {
      yield requestMe({type: "ui__requestMe"})
    }
  }
  yield put<AppActions>({type: "busy__setNotBusy"})
}

const requestMarkMatchdayScoringAsSeen = function*(action: ActionForType<"ui__markMatchdayScoringAsSeenAndPollCurrentMatchday">) {
  yield put<AppActions>({type: "busy__setBusy"})
  // mark as seen
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/matchday_mark_scoring_as_seen"),
    data: {

    } as ApiReq<ApiRequestMarkScoringAsSeen>
  }})
  const res1: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/matchday_mark_scoring_as_seen")))

  // afterwards: request next matchday
  yield put<AppActions>({type: "api__request", data: {
    action: apiPath("api__receive/matchday_get"),
    data: {

    } as ApiReq<ApiRequestMatchdayGet>
  }})
  const res2: AppActions = yield take<AppActions>((actionTypes(
    "api__request__error",
    "api__receive/matchday_get")))
  yield put<AppActions>({type: "busy__setNotBusy"})
}

export default function* () {
  yield all([
    takeEvery<AppActionType>("ui__requestConfig", requestConfig),
    takeEvery<AppActionType>("ui__requestMe", requestMe),
    takeEvery<AppActionType>("ui__requestCountdownToNextMatchday", requestCountdownToNextMatchday),
    takeEvery<AppActionType>("ui__requestMatchday", requestMatchday),
    takeEvery<AppActionType>("ui__markMatchdayScoringAsSeenAndPollCurrentMatchday", requestMarkMatchdayScoringAsSeen),
    takeEvery<AppActionType>("ui__makePick", requestMakePick),
    takeEvery<AppActionType>("ui__rankingGet", requestRankingGet),
    takeEvery<AppActionType>("ui__sendCompleteProfileForm", sendCompleteProfileForm),
  ])
}
