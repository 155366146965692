import { useEffect } from "react";

const disableBodyScroll = () => {
  const scrollY = window.scrollY
  document.body.style.position = "fixed";
  document.body.style.width = "100%"
  document.body.style.top = `-${scrollY}px`;
}

const enableBodyScroll = () => {
  if (document.body.style.position !== "fixed") { return }
  const scrollY = document.body.style.top;
  document.body.style.position = "";
  document.body.style.top = "";
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
}

export const useDisableBodyScrollWhenMounted = () => {
  useEffect(() => {
    disableBodyScroll()

    return () => { //cleanup
      enableBodyScroll()
    }
  }, [])
}

const useDisableBodyScroll = (disable: boolean) => {
  useEffect(() => {
    if (disable) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
  }, [disable])

  useEffect(() => { // make sure to cleanup when unmounting!

    return () => { // cleanup
      enableBodyScroll()
    }
  }, [])
}

export default useDisableBodyScroll
